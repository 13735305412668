/* eslint-disable object-curly-newline */
/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */
/* eslint-disable no-else-return */
/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable no-unused-vars */
import { createContext, useContext, useEffect, useState } from 'react';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
  sendPasswordResetEmail,
} from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { auth } from '../utils/firebase';
import {
  getItems,
  createItem,
  getItemById,
  getItemsByConditionGuest,
  updateItem,
} from '../api/api';

export const authContext = createContext();

export const useAuth = () => {
  const context = useContext(authContext);
  if (!context) throw new Error('There is not auth provider');
  return context;
};

export function AuthProvider({ children }) {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [idMenu, setIdMenu] = useState(null);
  const [loading, setLoading] = useState(false);
  const [validationEmail, setValidationEmail] = useState(false);
  const [inviteExit, setinviteExit] = useState(false);
  const [navinvitation, setNavInvitation] = useState(false);
  const [userID, setUserID] = useState();
  const [idCategory, setIdCategory] = useState(null);
  const [validationInv, setValidationInv] = useState();

  const signup = async (email, password, data) => {
    try {
      const fetchUser = await createUserWithEmailAndPassword(auth, email, password);
      const dataUser = {
        restaurantName: data.restaurantName,
        ownerName: data.userName,
        email: data.email,
        password: data.password,
        shortUrl: data.restaurantName.toLowerCase(),
        logo: '',
        photoUrl: '',
        landingImage: '',
        admin: true,
        rol: 'Owner',
        id: fetchUser?.user?.uid,
      };
      await createItem(dataUser, 'restaurants', fetchUser?.user?.uid);
      const newObj = {
        owner: fetchUser?.user?.uid,
        listRestaurants: [
          {
            restaurantId: fetchUser?.user?.uid,
            restaurantName: data.restaurantName,
            nameInvited: 'Owner',
            rol: 'Admin',
            email: data.email,
            invite: false,
          },
        ],
      };
      await createItem(newObj, 'guestPostbyAdmin', fetchUser?.user?.uid)
        .then(() => setinviteExit(true))
        .catch((err) => {
          setValidationEmail(true);
        });
      return fetchUser;
    } catch (err) {
      throw new Error('Email already in use');
    }
  };

  const login = async (email, password) => {
    const responseLogin = await signInWithEmailAndPassword(auth, email, password);
    setUser(responseLogin?.user);
    setUserID(responseLogin?.user?.uid);
    localStorage.setItem('token', responseLogin?.user?.uid);
    const invite = await getItemById(responseLogin?.user?.uid, 'guestPostbyAdmin');
    if (invite?.invite) {
      setValidationInv(invite?.invite);
    } else {
      setValidationInv(false);
    }
    return responseLogin;
  };

  const secountGuestUser = async (email, password, objectUser) => {
    let validationInvited;
    const idRestaurant = objectUser?.restaurantId;
    const res = await getItems('restaurants');
    const nameRestaurant = res.filter((item) => {
      let name;
      if (item?.id === idRestaurant) {
        name = item?.restaurantName;
      }
      return name;
    });
    const isOwner = await getItemsByConditionGuest(
      objectUser?.restaurantId,
      'guestPostbyAdmin',
      'owner',
    );
    isOwner.forEach((item) => {
      // eslint-disable-next-line no-return-assign
      item?.listRestaurants.filter((inv) => (inv?.email === email ? validationInvited = true : validationInvited = false));
    });
    const newObjectUpdate = {
      owner: objectUser?.restaurantId,
      listRestaurants: [
        ...(isOwner[0]?.listRestaurants || ''),
        {
          restaurantId: objectUser?.restaurantId,
          restaurantName: nameRestaurant[0]?.restaurantName,
          nameInvited: objectUser?.nameInvited,
          rol: 'Manager',
          invite: true,
          email: objectUser?.email,
        },
      ],
    };
    await updateItem(isOwner[0]?.id, newObjectUpdate, 'guestPostbyAdmin');
    const data = await getItemsByConditionGuest(email, 'restaurants', 'email');
    setUser(data);
  };

  const inviteUser = async (email, password, objectUser) => {
    const idRestaurant = objectUser?.restaurantId;
    const res = await getItems('restaurants');
    const nameRestaurant = res.filter((item) => {
      let name;
      if (item?.id === idRestaurant) {
        name = item?.restaurantName;
      }
      return name;
    });

    const newObject = {
      owner: objectUser?.restaurantId,
      listRestaurants: [
        {
          restaurantId: objectUser?.restaurantId,
          restaurantName: nameRestaurant[0]?.restaurantName,
          nameInvited: objectUser?.nameInvited,
          link: objectUser?.link || '',
          token: objectUser?.token || '',
          rol: objectUser?.rol,
          invite: true,
          email: objectUser?.email,
        },
      ],
    };

    const isOwner = await getItemsByConditionGuest(
      objectUser?.restaurantId,
      'guestPostbyAdmin',
      'owner',
    );

    let validationInvited;

    isOwner.forEach((item) => {
      // eslint-disable-next-line no-return-assign
      item?.listRestaurants.filter((inv) => (inv?.email === email ? validationInvited = true : validationInvited = false));
    });

    if (isOwner.length === 0) {
      setValidationEmail(false);
      setinviteExit(false);
      const response = await createUserWithEmailAndPassword(auth, email, password)
        .then(async (item) => {
          await createItem(newObject, 'guestPostbyAdmin', item?.user?.uid)
            .then(() => setinviteExit(true))
            .catch((err) => {
              setValidationEmail(true);
            });
        })
        .catch((err) => {
          setValidationEmail(true);
        })
        .catch((err) => err?.code);

      return response;
    } else if (validationInvited === false) {
      const response = await createUserWithEmailAndPassword(auth, email, password).then((res) => {
      }).catch((err) => err?.code);
      const newObjectUpdate = {
        owner: objectUser?.restaurantId,
        listRestaurants: [
          ...(isOwner[0]?.listRestaurants || ''),
          {
            restaurantId: objectUser?.restaurantId,
            restaurantName: nameRestaurant[0]?.restaurantName,
            nameInvited: objectUser?.nameInvited,
            link: objectUser?.link || '',
            token: objectUser?.token || '',
            rol: objectUser?.rol,
            invite: true,
            email: objectUser?.email,
          },
        ],
      };
      await updateItem(isOwner[0]?.id, newObjectUpdate, 'guestPostbyAdmin');
      return response;
    } else {
      const newObjectUpdate = {
        owner: objectUser?.restaurantId,
        listRestaurants: [
          ...(isOwner[0]?.listRestaurants || ''),
          {
            restaurantId: objectUser?.restaurantId,
            restaurantName: nameRestaurant[0]?.restaurantName,
            nameInvited: objectUser?.nameInvited,
            link: objectUser?.link || '',
            token: objectUser?.token || '',
            rol: objectUser?.rol,
            invite: true,
            email: objectUser?.email,
          },
        ],
      };
      await updateItem(isOwner[0]?.id, newObjectUpdate, 'guestPostbyAdmin');
    }
  };

  const logout = async () => {
    localStorage.removeItem('token');
    localStorage.removeItem('in');
    await signOut(auth);
    setUser();
    navigate('/');
  };

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    provider.addScope('https://www.googleapis.com/auth/userinfo.profile');
    auth.useDeviceLanguage();
    await signInWithPopup(auth, provider)
      .then(async (result) => {
        setUser(result?.user);
        const { user } = result;
        const colRef = await getItems('restaurants');
        const filterEmailGoogle = colRef?.some((item) => {
          if (item.email === user.email) {
            setUserID(item.id);
            localStorage.setItem('token', item.id);
            return true;
          } else {
            return false;
          }
        });

        if (filterEmailGoogle === false) {
          const obj = {
            restaurantName: 'Login with google',
            ownerName: user.displayName,
            email: user.email,
            password: 'Login with google',
            admin: true,
            id: user?.uid,
          };
          await createItem(obj, 'restaurants', user?.uid);
          return navigate('/menu');
        }

        if (filterEmailGoogle === false) {
          const obj = {
            restaurantName: `${user.displayName} Restaurant`,
            ownerName: user.displayName,
            email: user.email,
            password: 'Login with google',
            admin: true,
            id: user?.uid,
          };
          await createItem(obj, 'restaurants', user?.uid);
          const newObj = {
            owner: user?.uid,
            listRestaurants: [
              {
                restaurantId: user?.uid,
                restaurantName: `${user.displayName} Restaurant`,
                nameInvited: 'Owner',
                rol: 'Admin',
                email: user.email,
                invite: false,
              },
            ],
          };
          await createItem(newObj, 'guestPostbyAdmin', user?.uid)
            .then(() => setinviteExit(true))
            .catch((err) => {
              setValidationEmail(true);
            });
        }
        return navigate('/menu');
      })
      .catch((error) => {
        throw error;
      });
  };

  const resetPassword = async (email) => {
    const response = await sendPasswordResetEmail(auth, email);
    return response;
  };

  useEffect(() => {
    const unsuscribe = onAuthStateChanged(auth, (currenUser) => {
      setUser(currenUser);
      setLoading(false);
      if (currenUser?.email) {
        // return navigate('/menu');
      }
    });

    return () => unsuscribe();
  }, []);

  return (
    <authContext.Provider
      value={{
        signup,
        login,
        inviteUser,
        secountGuestUser,
        user,
        logout,
        loading,
        setLoading,
        signInWithGoogle,
        userID,
        resetPassword,
        idMenu,
        setIdMenu,
        idCategory,
        setIdCategory,
        validationEmail,
        inviteExit,
        setValidationEmail,
        setinviteExit,
        setNavInvitation,
        navinvitation,
        validationInv,
      }}
    >
      {children}
    </authContext.Provider>
  );
}

// recorrer el array list del guest en staff para que vea los invitados distintos en cada owner
// arreglar admins para que funcione como invitados
// recorrer las arrays en web admin para que se vea el menu segun el id del owner
