import React, { useState } from 'react';
import styled from 'styled-components';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
// eslint-disable-next-line import/no-extraneous-dependencies
import { toast } from 'react-toastify';
import Box from '@mui/material/Box';
import LoginIcon from '@mui/icons-material/Login';
import { Formik } from 'formik';
import * as yup from 'yup';
import Powered from '../../../assets/images/powered.png';
import { useAuth } from '../../../context/authContext';

const Contain = styled('div')`
  @media (min-width: 700px) {
    width: 100%;
    height: 100%;
    min-height: -webkit-fill-available;
  }
  background: #1e1e1e;
  @media (max-width: 699px) {
    min-height: -webkit-fill-available;
  }
`;
const ContainShadow = styled('div')`
  @media (min-width: 768px) {
    position: relative;
    top: -85%;
  }
`;
const Shadow = styled('div')`
  @media (min-width: 768px) {
    position: relative;
    top: 15%;
  }
`;
const ContentAnimate = styled('div')`
  @media (min-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: 23242a;
    overflow: hidden;
  }
`;
const AnimateBox = styled('div')({
  '@media (min-width: 768px)': {
    position: 'absolute',
    backgroundImage: 'linear-gradient(0deg,transparent,#1e1d1d,#141314)',
    backgroundSize: '300% 300%',
    maxHeight: '77%',
    maxWidth: '4%',
    minWidth: '100%',
    minHeight: '100%',
    borderRadius: '22px',
    transformOrigin: 'all 0.25s ease-out',
    animation:
      'rotate var(calc(70vw - 27vw * 1)) var(cubic-bezier(0.8, 0.2, 0.2, 0.8)) alternate infinite;',
    '@keyframes animate': {
      '0%': {
        transform: 'rotate(0deg)',
      },
      '100%': {
        transform: 'rotate(360deg)',
      },
    },
  },
});
const RowTitle = styled('div')`
  display: flex;
  flex-direction: row;
  @media (min-width: 768px) {
    place-content: center;
    position: relative;
    top: 19%;
  }
  @media (max-width: 500px) {
    padding-top: 5rem;
    margin-left: 6rem;
  }
  @media (max-width: 400px) {
    padding-top: 5rem;
    margin-left: 3rem;
  }
`;

const ColumnInput = styled('div')`
  align-items: center;
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    position: relative;
    top: 19%;
  }
  @media (max-width: 500px) {
    margin-top: 20px;
  }
`;

const TitleText = styled('p')`
  font-size: 36px;
  text-align: left;
  color: #ffffff;
  font-family: 'Gilroy';
  @media (max-width: 500px) {
    width: 250px;
    margin-top: 20px;
  }
`;

const TextSignIn = styled('p')`
  font-size: 24px;
  color: #ffffff;
  font-family: 'Gilroy';
  font-weight: 800;
  padding-right: 173px;
  align-self: center;
`;

const TextValidationPass = styled('p')`
  font-size: 10px;
  font-weight: 500;
  font-family: 'Poppins';
  text-align: center;
  color: red;
  margin-left: 0.5rem;
  @media (max-width: 500px) {
    margin-top: 20px;
  }
`;

const RowPowered = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media (min-width: 768px) {
    position: relative;
    top: 31%;
  }
  @media (max-width: 500px) {
    margin-top: 40px;
  }
`;

const ContainerSubmit = styled('div')`
    display: flex;
   @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
   }
 `;

const validationSchema = yup.object().shape({
  password: yup.string().min(8).max(255).required('The password is required'),
});

function ResetPassword() {
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [passValidation, setPassValidation] = useState(false);
  const navigate = useNavigate();
  const initialValues = {
    password: '',
    validationPass: '',
  };
  const { signup } = useAuth();

  const notify = (item) => toast(`${item}`);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleRedirect = (route) => {
    navigate(route);
  };

  const handleDataUser = async (data) => {
    setPassValidation(false);
    setLoading(true);
    // eslint-disable-next-line no-useless-catch
    try {
      if (data.password === data.validationPass) {
        const res = await signup(data.password);
        if (res) {
          setLoading(false);
          notify('Password Reset');
          setTimeout(() => {
            navigate('/');
          }, 2500);
        }
      } else {
        setPassValidation(true);
        notify('Error password not matched');
      }
    } catch (error) {
      notify(`${error.message}`);
      setLoading(false);
    }
  };

  return (
    <Contain>
      <ContentAnimate>
        <AnimateBox />
      </ContentAnimate>
      <ContainShadow>
        <Shadow>
          <RowTitle>
            <TitleText>Reset Password</TitleText>
          </RowTitle>
          <ColumnInput>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                setPassValidation(false);
                if (values.password === values.validationPass) {
                  handleDataUser(values);
                } else {
                  setPassValidation(true);
                }
              }}
            >
              {({
                handleChange, handleBlur, handleSubmit, values, errors, touched,
              }) => (
                <>
                  <OutlinedInput
                    style={{
                      fontFamily: 'Gilroy-Italic',
                      color: '#fff',
                      backgroundColor: '#262626',
                      marginleft: 8,
                      marginRight: 8,
                      marginTop: 15,
                      marginBottom: 15,
                      fontWeight: 300,
                      borderRadius: 10,
                      width: 318,
                      height: 55,
                      fontSize: 12,
                    }}
                    type={showPassword ? 'text' : 'password'}
                    value={values.password}
                    onChange={handleChange('password')}
                    onBlur={handleBlur('password')}
                    error={touched.password && errors.password ? true : undefined}
                    startAdornment={(
                      <InputAdornment position="start" style={{ color: '#fff' }}>
                        <LockOutlinedIcon style={{ width: 22, height: 22 }} />
                      </InputAdornment>
                    )}
                    endAdornment={(
                      <InputAdornment position="end" style={{ color: '#fff' }}>
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? (
                            <VisibilityOffOutlinedIcon
                              style={{ width: 22, height: 22, color: '#fff' }}
                            />
                          ) : (
                            <VisibilityOutlinedIcon
                              style={{ width: 22, height: 22, color: '#fff' }}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    )}
                    placeholder="Password"
                  />
                  <OutlinedInput
                    style={{
                      fontFamily: 'Gilroy-Italic',
                      color: '#fff',
                      backgroundColor: '#262626',
                      marginleft: 8,
                      marginRight: 8,
                      marginTop: 15,
                      marginBottom: 15,
                      fontWeight: 300,
                      borderRadius: 10,
                      width: 318,
                      height: 55,
                      fontSize: 12,
                    }}
                    type={showConfirmPassword ? 'text' : 'password'}
                    value={values.validationPass}
                    onChange={handleChange('validationPass')}
                    onBlur={handleBlur('validationPass')}
                    error={passValidation ? true : undefined}
                    startAdornment={(
                      <InputAdornment position="start" style={{ color: '#fff' }}>
                        <LockOutlinedIcon style={{ width: 22, height: 22 }} />
                      </InputAdornment>
                    )}
                    endAdornment={(
                      <InputAdornment position="end" style={{ color: '#fff' }}>
                        <IconButton
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <VisibilityOffOutlinedIcon
                              style={{ width: 22, height: 22, color: '#fff' }}
                            />
                          ) : (
                            <VisibilityOutlinedIcon
                              style={{ width: 22, height: 22, color: '#fff' }}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    )}
                    placeholder="Confirm Password"
                  />
                  {passValidation
                    && <TextValidationPass>Passwords do not match</TextValidationPass>}
                  <ContainerSubmit>
                    <TextSignIn onClick={() => handleRedirect('/')}>SignIn</TextSignIn>
                    {
                      loading
                        ? <CircularProgress thickness={1} />
                        : (
                          <IconButton
                            color="primary"
                            style={{
                              color: 'white',
                              fontSize: 'x-large',
                              border: '1px solid #7E5EB5',
                              filter: 'drop-shadow(1px 0px 6px #7E5EB5)',
                            }}
                            onClick={(e) => handleSubmit(e)}
                          >
                            <LoginIcon fontSize="x-large" />
                          </IconButton>
                        )
}
                  </ContainerSubmit>
                </>
              )}
            </Formik>
          </ColumnInput>
          <RowPowered>
            <Box component="img" src={Powered} alt="image powered mynu" />
          </RowPowered>
        </Shadow>
      </ContainShadow>
    </Contain>
  );
}
export default ResetPassword;
