/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-undef */
/* eslint-disable radix */
import React, { useState, useEffect, useMemo } from 'react';
import { Tabs, Form, Button, message, Input, Row, Col, Card, Upload, Select } from 'antd';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { Timestamp } from 'firebase/firestore';
import { PageHeaderAlt } from '../../../../../../components/layout-components/PageHeaderAlt';
import Flex from '../../../../../../components/shared-components/Flex';
import { ImageSvg } from '../../../../../../assets/svg/icon';
import CustomIcon from '../../../../../../components/util-components/CustomIcon';
import {
  createItemCustom,
  uploadFile,
  getItemsByConditionGuest,
  updateItem,
  getProductsValidationExist,
} from '../../../../../../api/api';
import { useAuth } from '../../../../../../context/authContext';

// DRAG AND OPTION INVENTORY
const { Dragger } = Upload;
const { Option } = Select;
const legthUnit = ['bottles', 'servings'];

// GENERAL RULES
const rules = {
  name: [
    {
      required: true,
      message: 'Please enter product name',
    },
  ],
  description: [
    {
      required: true,
      message: 'Please enter product description',
    },
  ],
  price: [
    {
      required: true,
      message: 'Please enter product price',
    },
  ],
};

// IMG GENERAL

const beforeUpload = (file) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
};

function ProductForm(props) {
  // GENERAL STATE
  const { user } = useAuth();
  const paramIdRestaurant = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [uploadLoading, setUploadLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [nameImg, setNameImg] = useState();
  const [dataImg, setDataImg] = useState();
  const [preview, setPreview] = useState();
  const [themeInputs, setThemeInputs] = useState();
  const [themeCell, setThemeCell] = useState();
  const { headerNavColor, currentTheme } = useSelector((state) => state.theme);
  const isDarkTheme = currentTheme === 'dark';
  // INVENTORY STATE
  const [typeQuantity, setTypeQuantity] = useState(legthUnit[0]);
  const [numbBottles, setNumbBottles] = useState();

  // ALL FILE COLOR
  const colorText = useMemo(() => {
    if (isDarkTheme) {
      setThemeInputs('#1b2531');
      setThemeCell('rgb(0 0 0)');
      return '#fff';
    }
    setThemeCell('rgb(148 152 163)');
    setThemeInputs('#fafafb');
    return '#455560';
  }, [isDarkTheme]);

  // EFFECT FROM GENERAL IMG
  useEffect(() => {
    setPreview(undefined);
    if (!selectedFile) {
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    // eslint-disable-next-line consistent-return
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const bucketImageProduct = (data, nameI) => {
    setUploadLoading(true);
    if (!data) {
      setSelectedFile(undefined);
      setUploadLoading(false);
      return;
    }
    setUploadLoading(false);
    setSelectedFile(data?.originFileObj);

    setDataImg(data?.originFileObj);
    setNameImg(data?.originFileObj?.name);
  };

  function formatPhoneNumber(value) {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, '');

    // Aplica el formato (123) 456-7890
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6)}`;
  }

  const handlePhoneNumberChange = (e) => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    form.setFieldsValue({ telephoneNumber: formattedPhoneNumber });
  };

  const onFinish = () => {
    setSubmitLoading(true);
    form
      .validateFields()
      .then(async (values) => {
        const productExit = await getProductsValidationExist('products', paramIdRestaurant?.idRestaurant, paramIdRestaurant?.idMenu, paramIdRestaurant?.idCategory, values?.name);

        if (productExit.length !== 0) {
          setSubmitLoading(false);
          message.error('This product already exists');
          return;
        }

        const time = Timestamp.fromDate(new Date()).toDate();
        const sendUrlImg = await uploadFile(dataImg, nameImg);

        const sumBarBottles = (parseFloat(values?.quantityBar1) || 0)
      + (parseFloat(values?.quantityBar2) || 0)
      + (parseFloat(values?.quantityBar3) || 0);

        const totalCages = (parseFloat(values?.quantityCagesBar1) || 0)
      + (parseFloat(values?.quantityCagesBar2) || 0)
      + (parseFloat(values?.quantityCagesBar3) || 0);

        const totalBottles = sumBarBottles + (totalCages * values?.quantityForCages || 0);

        const obj = {
          createdIn: 'web-admin',
          dateOfCreation: time,
          categoryId: paramIdRestaurant?.idCategory,
          image: sendUrlImg,
          keyword: values?.name?.toLowerCase(),
          menuId: paramIdRestaurant?.idMenu,
          restaurantId: paramIdRestaurant?.idRestaurant,
          name: values?.name,
          description: values?.description,
          price: values?.price,
          views: 0,
          abv: values?.abv || '',
          body: values?.body || '',
          brand: values?.brand || '',
          countryState: values?.countryState || '',
          region: values?.region || '',
          sku: values?.sku || '',
          taste: values?.taste || '',
          type: values?.type || '',
          varietal: values?.varietal || '',
          deleted: false,
          enabled: true,
          position: 0,
          positionInCategory: 0,
          purchaseCost: values?.purchaseCost || '',
          servings: values?.servings || '',
          upc: values?.upc || '',
          weight: values?.weight || '',
          par: values?.par || '',
          quantityPerCage: values?.quantityPerCage || '',
          telephoneNumber: values?.telephoneNumber || '',
          inventory: [
            {
              quantity: totalBottles || null,
              quantityBar1: values?.quantityBar1 || '',
              quantityBar2: values?.quantityBar2 || '',
              quantityBar3: values?.quantityBar3 || '',
              quantityCagesBar1: values?.quantityCagesBar1 || '',
              quantityCagesBar2: values?.quantityCagesBar2 || '',
              quantityCagesBar3: values?.quantityCagesBar3 || '',
              quantityForCages: totalCages || '',
            },
          ],
        };

        const logObjet = {
          idRestaurant: paramIdRestaurant?.idRestaurant,
          log: [
            {
              user: user?.displayName,
              email: user?.email,
              type: 'create new product',
              time,
              nameItem: values?.name,
              properties: obj,
            },
          ],
        };
        await createItemCustom(logObjet, 'log');

        const response = await createItemCustom(obj, 'products');

        const logObjetSave = {
          idRestaurant: paramIdRestaurant?.idRestaurant,
          log: [
            {
              user: user?.displayName,
              email: user?.email,
              type: 'create new product',
              time,
              nameItem: values?.name,
              idItem: response,
              newData: [
                {
                  typeBottles: typeQuantity || '',
                  quantity: numbBottles || '',
                },
              ],
              oldData: [],
            },
          ],
        };
        await createItemCustom(logObjetSave, 'logInventory');

        setSubmitLoading(false);
        message.success('Item Created');
        navigate(-1);
      })
      .catch((info) => {
        console.log(info);
        setSubmitLoading(false);
        message.error('Error In Creating Item');
      });
  };

  const handleImg = () => {
    setDataImg();
    setNameImg();
  };

  const onBack = () => {
    navigate(-1);
  };

  // INENTORY

  const handleChangeValueBottles = (value) => {
    setNumbBottles(value?.target?.value);
  };
  const handleChangeSelectBottles = (value) => {
    setTypeQuantity(value);
  };

  return (
    <Form layout="vertical" form={form} name="advanced_search" className="ant-advanced-search-form">
      <PageHeaderAlt className="border-bottom" overlap>
        <div className="container">
          <Flex
            className="py-2"
            mobileFlex={false}
            justifyContent="space-between"
            alignItems="center"
          >
            <h2 className="mb-3" style={{ color: colorText }}>
              Add New Product
              {' '}
            </h2>
            <div className="mb-3">
              <Button className="mr-2" onClick={() => onBack()}>
                Discard
              </Button>
              <Button
                type="primary"
                onClick={() => onFinish()}
                htmlType="submit"
                loading={submitLoading}
              >
                Add
              </Button>
            </div>
          </Flex>
        </div>
      </PageHeaderAlt>
      <div className="container">
        <Tabs
          defaultActiveKey="1"
          style={{ marginTop: 60 }}
          items={[
            {
              label: 'General',
              key: '1',
              children: (
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={17}>
                    <Card title="Basic Info">
                      <Form.Item name="name" label="Product name" rules={rules.name}>
                        <Input placeholder="Product Name" />
                      </Form.Item>
                      <Form.Item name="description" label="Description" rules={rules.description}>
                        <Input.TextArea rows={4} />
                      </Form.Item>
                      <Col xs={24} sm={24} md={12}>
                        <Form.Item name="price" label="Price" rules={rules.price}>
                          <Input
                            type="number"
                            className="w-100"
                            formatter={(value) =>
                              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                            }
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                          />
                        </Form.Item>
                      </Col>
                    </Card>
                    <Card title="Additional info">
                      <Row gutter={16}>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item name="abv" label="ABV">
                            <Input className="w-100" value={0} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item name="body" label="Body">
                            <Input className="w-100" />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item name="brand" label="Brand">
                            <Input className="w-100" min={0} max={100} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item name="countryState" label="Country-State">
                            <Input className="w-100" min={0} max={100} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item name="region" label="Region">
                            <Input className="w-100" min={0} max={100} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item name="sku" label="Sku">
                            <Input className="w-100" min={0} max={100} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item name="taste" label="Taste">
                            <Input className="w-100" min={0} max={100} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item name="type" label="Type">
                            <Input className="w-100" min={0} max={100} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item name="varietal" label="Varietal">
                            <Input className="w-100" min={0} max={100} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item name="par" label="PAR">
                            <Input className="w-100" min={0} max={100} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            name="telephoneNumber"
                            label="Supplier number of this product"
                            rules={[
                              {
                                pattern: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
                                message:
                              'The phone number is not valid, it must have a phone number format, e.g.: (123) 456-7890',
                              },
                            ]}
                          >
                            <Input
                              className="w-100"
                              onChange={handlePhoneNumberChange}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                  <Col xs={24} sm={24} md={7}>
                    <Card title="Media">
                      <Dragger
                        beforeUpload={beforeUpload}
                        onClick={handleImg}
                        onChange={(e) => bucketImageProduct(e?.file, e?.file?.name, e)}
                      >
                        {preview ? (
                          <img src={preview} alt="avatar" className="img-fluid" />
                        ) : (
                          <div>
                            {uploadLoading ? (
                              <div>
                                <LoadingOutlined className="font-size-xxl text-primary" />
                                <div className="mt-3">Uploading</div>
                              </div>
                            ) : (
                              <div>
                                <CustomIcon className="display-3" svg={preview || ImageSvg} />
                                <p>Click or drag file to upload</p>
                              </div>
                            )}
                          </div>
                        )}
                      </Dragger>
                    </Card>
                    <Card title="Units">
                      <Row gutter={16}>
                        <Col xs={24} sm={12} md={12} lg={12}>
                          <Form.Item name="quantityBar1" label="Bottles in Bar 1">
                            <Input
                              type="number"
                              className="w-100"
                              formatter={(value) =>
                                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                              }
                              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12}>
                          <Form.Item name="quantityCagesBar1" label="Cages in Bar 1">
                            <Input
                              type="number"
                              className="w-100"
                              formatter={(value) =>
                                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                              }
                              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col xs={24} sm={12} md={12} lg={12}>
                          <Form.Item name="quantityBar2" label="Bottles in Bar 2">
                            <Input
                              type="number"
                              className="w-100"
                              formatter={(value) =>
                                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                              }
                              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12}>
                          <Form.Item name="quantityCagesBar2" label="Cages in Bar 2">
                            <Input
                              type="number"
                              className="w-100"
                              formatter={(value) =>
                                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                              }
                              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col xs={24} sm={12} md={12} lg={12}>
                          <Form.Item name="quantityBar3" label="Bottles in Bar 3">
                            <Input
                              type="number"
                              className="w-100"
                              formatter={(value) =>
                                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                              }
                              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12}>
                          <Form.Item name="quantityCagesBar3" label="Cages in Bar 3">
                            <Input
                              type="number"
                              className="w-100"
                              formatter={(value) =>
                                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                              }
                              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              ),
            },
            {
              /* INVENTORY */
            },
            {
              label: 'Inventory',
              key: '3',
              children: (
                <Card title="Inventory">
                  <Row gutter={16}>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item name="purchaseCost" label="Purchase cost">
                        <Input className="w-100" value={0} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item name="servings" label="Servings">
                        <Input className="w-100" value={0} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item name="upc" label="UPC">
                        <Input className="w-100" value={0} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item name="weight" label="Weight">
                        <Input className="w-100" value={0} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item name="quantityForCages" label="Quantity per Cages">
                        <Input className="w-100" min={0} max={100} />
                      </Form.Item>
                    </Col>
                    {/* <Col xs={24} sm={24} md={12}>
                      <Form.Item name="height" label="Height">
                        <Input
                          addonAfter={(
                            <Form.Item name="heightUnit" noStyle>
                              <Select style={{ minWidth: 70 }}>
                                {legthUnit.map((unit) => (
                                  <Option key={unit} value={unit}>
                                    {unit}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          )}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item name="weight" label="Weight">
                        <Input
                          addonAfter={(
                            <Form.Item name="weightUnit" noStyle>
                              <Select style={{ minWidth: 70 }}>
                                {weightUnit.map((unit) => (
                                  <Option key={unit} value={unit}>
                                    {unit}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          )}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item name="InventoryFees" label="Inventory fees">
                        <InputNumber
                          className="w-100"
                          formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        />
                      </Form.Item>
                    </Col> */}
                  </Row>
                </Card>
              ),
            },
          ]}
        />
      </div>
    </Form>
  );
}

export default ProductForm;
