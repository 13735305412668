/* eslint-disable no-console */
/* eslint-disable quotes */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Form, Button, TimePicker, InputNumber } from 'antd';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { schemaDelivery } from './deliverySchema';

const ACTUAL_DATE = new Date();
const FORMAT = 'hh:mm a';

// eslint-disable-next-line import/prefer-default-export
export function FormDelivery({ handleSubmit, isLoading, setIsLoading, textStatus, statusCode }) {
  const [hourDate, setHourDate] = useState('');
  const [finish, setFinish] = useState(false);
  const [errorQuantity, setErrorQuantity] = useState(false);

  const onSubmit = async (data, resetForm) => {
    try {
      setIsLoading(true);
      await handleSubmit(data);
      resetForm();
      setFinish(true);
    } catch (e) {
      setFinish(false);
      console.log(e);
    }
  };

  const formik = useFormik({
    initialValues: {
      quantityBottles: '',
      quantityCases: '',
      date: '',
      hour: '',
    },
    validationSchema: schemaDelivery,
    onSubmit: async (values, { resetForm }) => {
      if (!values.quantityBottles && !values.quantityCases) {
        setErrorQuantity(true);
        return;
      }

      setErrorQuantity(false);
      await onSubmit(values, resetForm);
    },
  });

  const handleTime = (e) => {
    setHourDate(e);
    const timeInput = dayjs(e);

    // Formatea la hora en el formato HH:mm
    const timeFormat = timeInput.format('hh:mm a');
    formik.setFieldValue('hour', timeFormat);
  };

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <Form onFinish={formik.handleSubmit}>
      <Form.Item name="quantityBottles" label="Quantity of Bottles">
        <InputNumber
          className="w-100"
          type="number"
          name="quantityBottles"
          id="quantityBottles"
          placeholder="Quantity"
          onChange={(e) => formik.setFieldValue('quantityBottles', e)}
          value={formik.values.quantity}
          onBlur={formik.handleBlur}
        />
        {errorQuantity && (
          <div className="alertInputContainer">
            <p className="textAlertError">* Please enter a number of bottles or boxes.</p>
          </div>
        )}
      </Form.Item>
      <Form.Item name="quantityCases" label="Quantity of Cases">
        <InputNumber
          type="number"
          className="w-100"
          name="quantityCases"
          id="quantityCases"
          placeholder="Quantity"
          onChange={(e) => formik.setFieldValue('quantityCases', e)}
          value={formik.values.quantityCases}
          onBlur={formik.handleBlur}
        />
      </Form.Item>
      <Form.Item label="Date">
        <Calendar
          minDate={ACTUAL_DATE}
          value={formik.values.date}
          onChange={(e) => formik.setFieldValue('date', e)}
          className="calendar"
        />
        {formik.touched.date && formik.errors.date ? (
          <div className="alertInputContainer">
            <p className="textAlertError">*{formik.errors.date}</p>
          </div>
        ) : (
          ''
        )}
      </Form.Item>

      <Form.Item name="hour" label="Hour">
        <TimePicker
          name="hour"
          id="hour"
          className="w-100"
          use12Hours
          showTime={{ use12Hours: true, format: 'hh:mm a' }}
          format={FORMAT}
          value={hourDate}
          onChange={handleTime}
        />
        {formik.touched.hour && formik.errors.hour ? (
          <div className="alertInputContainer">
            <p className="textAlertError">*{formik.errors.hour}</p>
          </div>
        ) : (
          ''
        )}
      </Form.Item>
      <Form.Item>
        <div className="divButton">
          <Button loading={isLoading} htmlType="submit" className="submitButton" type="primary">
            Send
          </Button>
          {finish && (
            <div className="alertInputContainer">
              <p className={statusCode === 1 ? 'textAlertSuccess' : 'textAlertError'}>
                {textStatus}
              </p>
            </div>
          )}
        </div>
      </Form.Item>
    </Form>
  );
}
