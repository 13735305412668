import { lazy } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { useAuth } from '../context/authContext';
/* import Spinner from '../components/Spinner'; */

const AppLayout = lazy(() => import('../layouts/AppLayout'));
const AuthLayout = lazy(() => import('../layouts/AuthLayout'));

export default function ProtectedRoute() {
  const { user, loading } = useAuth();
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  /* const blankLayout = useSelector((state) => state.theme.blankLayout); */

  const Layout = AppLayout;
  const OutLayout = AuthLayout;

  // eslint-disable-next-line max-len, jsx-a11y/heading-has-content
  if (loading) return <h1 />;
  if (!user) return navigate('/');

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {token ? (
        <Layout>
          <Outlet />
        </Layout>
      ) : (
        <OutLayout>
          <Outlet />
        </OutLayout>
      )}
    </>
  );
}
