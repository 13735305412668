/* eslint-disable no-tabs */
/* eslint-disable import/prefer-default-export */
export function GoogleSVG() {
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor">
      <g>
        <path
          fill="#59C36A"
          d="M827.301,875.968c-84.521,71.388-194.169,116.693-313.528,116.693c-177.609,0-333.52-97.848-418.041-240.62
                  l31.646-145.858l140.255-26.039c32.553,105.078,130.779,182.178,246.141,182.178c55.964,0,107.937-17.703,150.767-49.112
                  l134.777,20.558L827.301,875.968z"
        />
        <path
          fill="#00A66C"
          d="M827.301,875.968l-27.984-142.201l-134.777-20.558c-42.83,31.409-94.803,49.112-150.767,49.112v230.34
                  C633.132,992.661,742.779,947.355,827.301,875.968z"
        />
        <g id="Connected_Home_1_">
          <g>
            <g>
              <g>
                <path
                  fill="#FFDA2D"
                  d="M256.781,505.331c0,26.267,3.998,51.396,10.851,74.813l-171.9,171.897
                                  c-42.83-71.957-69.29-156.48-69.29-246.71c0-90.233,26.46-174.754,69.29-246.711l137.965,23.743l33.936,148.154
                                  C260.779,453.932,256.781,479.06,256.781,505.331L256.781,505.331z"
                />
              </g>
            </g>
          </g>
        </g>
        <path
          fill="#4086F4"
          d="M1001.103,505.331c0,148.48-68.719,281.547-173.802,370.637L664.539,713.209
                  c33.121-23.988,61.107-55.971,79.384-93.66h-230.15c-15.993,0-28.556-12.567-28.556-28.554V419.666
                  c0-15.99,12.563-28.554,28.556-28.554h450.78c13.707,0,25.698,9.708,27.983,23.412
                  C998.247,444.225,1001.103,475.063,1001.103,505.331L1001.103,505.331z"
        />
        <path
          fill="#4175DF"
          d="M743.924,619.549c-18.275,37.689-46.264,69.672-79.382,93.66l162.759,162.759
                  c105.083-89.09,173.802-222.153,173.802-370.637c0-30.269-2.855-61.106-8.567-90.807c-2.284-13.704-14.278-23.412-27.984-23.412
                  H513.772v228.437H743.924z"
        />
        <path
          fill="#FF641A"
          d="M835.297,154.107c0.571,7.996-2.855,15.422-7.996,21.131L705.086,296.881
                  c-9.704,10.278-25.694,11.421-37.118,2.855c-45.119-33.693-98.231-51.396-154.195-51.396
                  c-115.361,0-213.588,77.095-246.141,182.178L95.731,258.62C180.253,115.848,336.163,18,513.772,18
                  c113.647,0,224.439,41.88,311.244,114.978C831.298,138.119,834.723,146.112,835.297,154.107L835.297,154.107z"
        />
        <path
          fill="#F03800"
          d="M667.966,299.736c11.422,8.567,27.411,7.423,37.119-2.855l122.214-121.643
                  c5.143-5.709,8.569-13.133,7.996-21.131c-0.575-7.997-3.999-15.988-10.279-21.13C738.212,59.88,627.42,18,513.772,18v230.34
                  C569.736,248.34,622.849,266.043,667.966,299.736z"
        />
      </g>
    </svg>
  );
}

export function RestaurantSVG() {
  return (
    <svg
      style={{
        width: '1em',
        height: '1em',
        verticalAlign: 'middle',
        fill: 'currentColor',
        overflow: 'hidden',
        color: '#007bff',
      }}
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="#007bff"
        d="M51.2 710.4h921.6c12.8 0 19.2-6.4 19.2-19.2s-6.4-19.2-19.2-19.2h-25.6c0-115.2-44.8-224-128-307.2-51.2-57.6-115.2-96-185.6-115.2C627.2 179.2 576 128 512 128c-64 0-115.2 51.2-121.6 121.6-70.4 19.2-134.4 57.6-185.6 108.8-83.2 83.2-128 192-128 307.2H51.2c-12.8 0-19.2 6.4-19.2 19.2s6.4 25.6 19.2 25.6M512 166.4c38.4 0 70.4 32 76.8 70.4-25.6 0-51.2-6.4-76.8-6.4s-51.2 0-76.8 6.4c6.4-38.4 38.4-70.4 76.8-70.4m0 108.8c217.6 0 390.4 179.2 390.4 396.8H121.6C121.6 448 294.4 275.2 512 275.2m460.8 582.4H51.2c-12.8 0-19.2 6.4-19.2 19.2 0 12.8 6.4 19.2 19.2 19.2h921.6c12.8 0 19.2-6.4 19.2-19.2 0-12.8-6.4-19.2-19.2-19.2M403.2 396.8c-51.2 12.8-102.4 44.8-140.8 89.6-32 32-44.8 64-44.8 70.4-6.4 6.4 0 19.2 12.8 19.2h6.4c6.4 6.4 12.8 0 19.2-6.4 0 0 44.8-108.8 160-128 12.8 0 19.2-12.8 19.2-25.6-12.8-12.8-19.2-19.2-32-19.2"
      />
      <path
        fill="#007bff"
        d="M972.8 908.8H51.2c-19.2 0-32-12.8-32-32s12.8-32 32-32h921.6c19.2 0 32 12.8 32 32s-12.8 32-32 32zM51.2 870.4c-6.4 0-6.4 6.4-6.4 6.4 0 6.4 0 6.4 6.4 6.4h921.6c6.4 0 6.4-6.4 6.4-6.4 0-6.4-6.4-6.4-6.4-6.4H51.2z m921.6-147.2H51.2c-19.2 0-32-12.8-32-32s12.8-32 32-32H64c6.4-115.2 51.2-224 128-300.8 51.2-51.2 115.2-89.6 185.6-115.2 6.4-70.4 64-128 134.4-128s128 57.6 134.4 121.6c70.4 19.2 134.4 57.6 185.6 115.2 83.2 83.2 128 192 128 300.8h12.8c19.2 0 32 12.8 32 32s-12.8 38.4-32 38.4zM51.2 678.4c-6.4 0-6.4 6.4-6.4 6.4s0 6.4 6.4 6.4h921.6c6.4 0 6.4-6.4 6.4-6.4s0-6.4-6.4-6.4h-38.4v-12.8c0-115.2-44.8-217.6-121.6-300.8-51.2-44.8-115.2-83.2-185.6-102.4L620.8 256v-6.4C614.4 192 569.6 140.8 512 140.8c-57.6 0-102.4 51.2-108.8 108.8V256h-6.4c-70.4 25.6-134.4 64-179.2 115.2-83.2 76.8-121.6 185.6-128 294.4v12.8H51.2z m864 0H108.8v-12.8C108.8 441.6 294.4 256 512 256s403.2 185.6 403.2 409.6v12.8z m-780.8-25.6H896c-12.8-204.8-179.2-364.8-384-364.8S140.8 448 134.4 652.8z m102.4-57.6h-12.8c-19.2-12.8-25.6-32-19.2-51.2 0-6.4 19.2-38.4 44.8-70.4 44.8-44.8 96-76.8 153.6-89.6 6.4 0 19.2 0 25.6 6.4 6.4 6.4 12.8 12.8 12.8 19.2 0 19.2-6.4 32-25.6 38.4-102.4 19.2-147.2 115.2-147.2 128-6.4 6.4-19.2 19.2-32 19.2z m172.8-185.6c-51.2 12.8-102.4 38.4-140.8 83.2-25.6 32-38.4 64-38.4 64 0 6.4 0 6.4 6.4 12.8 6.4 0 6.4 0 6.4-6.4 6.4-12.8 57.6-115.2 166.4-134.4 6.4 0 6.4-6.4 6.4-6.4 0-6.4 0-6.4-6.4-12.8zM608 256l-19.2-6.4c-51.2-6.4-102.4-6.4-153.6 0l-19.2 6.4v-19.2c12.8-44.8 51.2-83.2 96-83.2s83.2 32 89.6 83.2l6.4 19.2zM512 217.6c19.2 0 38.4 0 64 6.4-12.8-25.6-38.4-44.8-64-44.8s-51.2 19.2-64 38.4h64z"
      />
    </svg>
  );
}

export function ReplySVG() {
  return (
    <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor">
      <path
        d="M537.016,909.264c-7.034,0-14.006-2.372-19.671-6.97L18.508,496.986C11.232,491.055,7,482.161,7,472.781
			c0-9.374,4.232-18.267,11.508-24.204L517.345,43.272c9.318-7.551,22.258-9.104,33.064-3.959
			c10.871,5.175,17.785,16.135,17.785,28.162v219.277c243.388,16.107,436.483,219.246,436.483,466.625v62.353
			c0,12.18-7.097,23.235-18.147,28.314c-11.054,5.119-24.054,3.292-33.311-4.626l-52.55-45.027
			c-93.318-79.986-210.359-126.841-332.476-133.66v217.36c0,12.022-6.914,22.986-17.785,28.158
			C546.146,908.262,541.58,909.268,537.016,909.264L537.016,909.264z M125.715,472.781L506.65,782.309V614.776
			c0-15.697,12.702-28.401,28.399-28.401c134.946,0,265.707,48.367,368.18,136.193l0.972,0.834
			c-2.664-201.286-167.231-364.213-369.148-364.213c-15.699,0-28.4-12.704-28.4-28.399V163.258
			C506.65,163.258,125.715,472.781,125.715,472.781L125.715,472.781z"
      />
    </svg>
  );
}

export function FacebookSVG() {
  return (
    <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor">
      <g>
        <path
          fill="#4A7AFF"
          d="M1015.363,506.525c0,279.749-226.775,506.524-506.525,506.524c-279.749,0-506.524-226.775-506.524-506.524
	C2.313,226.775,229.089,0,508.838,0C788.588,0,1015.363,226.775,1015.363,506.525z"
        />
        <path
          fill="#FFFFFF"
          d="M663.688,149.015v114.697c0,0-131.686-19.113-131.686,65.843v84.957h118.941L636.08,544.072H532.002v337.709
	H400.316V544.072l-112.572-2.126V414.512H398.19V316.81c0,0-7.288-145.343,135.938-172.038
	C593.602,133.68,663.688,149.015,663.688,149.015z"
        />
        <path
          fill="#DCE1EB"
          d="M663.688,263.712V149.015c0,0-70.086-15.335-129.56-4.243c-9.291,1.73-17.932,3.973-25.993,6.623v730.387
	h23.867V544.072h104.07l14.871-129.561H532.002c0,0,0,0,0-84.957C532.002,244.599,663.688,263.712,663.688,263.712z"
        />
      </g>
    </svg>
  );
}

export function TwitterSVG() {
  return (
    <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor">
      <path
        fill="#03A9F4"
        d="M1006.701,163.276c-6.71-7.344-17.513-9.249-26.334-4.649c-9.059,4.107-18.431,7.508-28.042,10.136
      c14.983-19.171,26.285-40.938,33.356-64.208c3.113-11.713-3.859-23.729-15.573-26.834c-6.251-1.668-12.92-0.484-18.218,3.219
      c-33.365,18.063-68.824,31.936-105.59,41.3c-75.666-71.428-190.939-80.332-276.685-21.372
      c-66.047,44.667-102.419,121.845-94.796,201.207c-143.168-12.197-274.902-83.141-363.884-195.983
      c-4.574-5.527-11.491-8.575-18.653-8.206c-7.262,0.436-13.833,4.443-17.545,10.703c-30.202,49.365-38.951,108.916-24.231,164.878
      c7.548,28.757,20.485,55.813,38.136,79.74c-7.942-3.894-15.434-8.682-22.292-14.268c-9.413-7.632-23.229-6.193-30.86,3.22
      c-3.17,3.909-4.904,8.788-4.912,13.815c1.314,81.186,45.891,155.489,116.917,194.842c-9.586-1.175-19.04-3.204-28.264-6.054
      c-11.607-3.491-23.846,3.088-27.338,14.687c-1.519,5.06-1.166,10.505,1.004,15.326c30.826,69.055,92.447,119.488,166.24,136.039
      c-70.303,39.665-151.25,56.33-231.5,47.657c-10.399-1.323-20.255,5.002-23.385,15.015c-3.228,9.931,0.993,20.765,10.095,25.891
      c98.254,58.384,210.029,90.139,324.3,92.16c109.632-0.403,216.707-33.152,307.808-94.132
      C820.37,681.983,928.769,474.763,913.672,289.359c37.184-27.533,69.439-61.153,95.403-99.446
      C1014.406,181.519,1013.437,170.586,1006.701,163.276z"
      />
    </svg>
  );
}

export function NavbarIconSVG() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="218" height="38" viewBox="0 0 218 38" fill="none">
      <path d="M1.51749 36.4133V3.95879C1.51749 3.36737 2.29966 3.14903 2.60975 3.65389L17.7005 28.2236C19.9974 31.9634 25.4637 31.9634 27.7607 28.2236L42.8514 3.65389C43.1615 3.14902 43.9437 3.36737 43.9437 3.95879V36.4133M52.7825 1.87554L72.8171 21.7787M72.8171 21.7787L92.8516 1.87554M72.8171 21.7787V36.9987M99.9227 36.1949V3.50011C99.9227 1.32885 102.64 0.326884 104.066 1.9722L132.313 34.5605C133.739 36.2058 136.456 35.2038 136.456 33.0326V0.337807M144.706 0.704765V22.9494C144.706 30.7086 151.038 36.9987 158.848 36.9987H162.383C170.194 36.9987 176.526 30.7086 176.526 22.9494V0.704765M184.775 0.704765V22.9494C184.775 30.7086 191.107 36.9987 198.917 36.9987H202.453C210.263 36.9987 216.595 30.7086 216.595 22.9494V0.704765" stroke="url(#paint0_linear_25_26)" strokeWidth="1.62937" />
      <defs>
        <linearGradient id="paint0_linear_25_26" x1="246.057" y1="-5.1491" x2="248.384" y2="68.6105" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function ImageSvg() {
  return (
    <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor">
      <g>
        <g>
          <path
            fill="#E5E1E5"
            d="M163.033,1011.756L2.746,191.353c-4.863-24.879,11.365-48.991,36.245-53.852l124.042-24.234l194.642-42.82
          l279.663,941.308H163.033V1011.756z"
          />
        </g>
        <path fill="#99E6FC" d="M163.033,680.979L68.355,196.393l94.678-18.501l143.802-23.615l62.994,584.599L163.033,680.979z" />
        <g id="XMLID_159_">
          <g>
            <path
              fill="#F9F7F8"
              d="M1014.357,64.9v957.461c0,25.351-20.549,45.899-45.899,45.899H208.93
            c-25.351,0-45.901-20.549-45.901-45.899V64.9c0-25.35,20.551-45.9,45.901-45.9h759.528C993.809,19,1014.357,39.551,1014.357,64.9
            z"
            />
          </g>
          <path
            fill="#EFEDEF"
            d="M574.473,971.206c-90.848,0-164.495-73.646-164.495-164.493V19H208.93
          c-25.351,0-45.901,20.551-45.901,45.9v957.461c0,25.351,20.551,45.899,45.901,45.899h759.528
          c25.351,0,45.899-20.549,45.899-45.899v-51.155H574.473z"
          />
          <path
            fill="#FEC165"
            d="M950.933,737.554V234.861c0-7.122-5.774-12.896-12.897-12.896H239.354c-7.12,0-12.896,5.774-12.896,12.896
          v502.692H950.933z"
          />
          <path fill="#FDB441" d="M409.978,221.965H239.354c-7.12,0-12.896,5.774-12.896,12.896v502.692h183.52V221.965z" />
          <circle fill="#FEE903" cx="588.693" cy="600.309" r="246.948" />
          <path
            fill="#F4D902"
            d="M409.978,770.729V429.889c-42.274,44.316-68.229,104.339-68.229,170.419
          C341.748,666.391,367.703,726.41,409.978,770.729z"
          />
          <g>
            <path
              fill="#99E6FC"
              d="M902.813,668.316c-57.591-25.393-122.604-28.267-182.203-8.034l-51.163,17.336
            c-52.369,17.759-109.135,17.759-161.505,0l-51.163-17.336c-59.602-20.232-124.611-17.358-182.182,8.034l-48.142,21.226v105.269
            l80.12,33.354h599.658l44.699-33.354V689.542L902.813,668.316z"
            />
            <path
              fill="#62DBFB"
              d="M409.978,828.165V649.264c-45.72-6.239-92.605,0.184-135.379,19.053l-48.141,21.226v105.269
            l80.119,33.354H409.978z"
            />
            <path
              fill="#62DBFB"
              d="M950.933,794.811v61.709c0,5.452-4.424,9.878-9.879,9.878H236.332c-5.453,0-9.877-4.426-9.877-9.878
            v-61.709l48.142-21.229c57.57-25.39,122.58-28.268,182.182-8.055l51.163,17.358c52.37,17.759,109.136,17.759,161.505,0
            l51.163-17.358c59.6-20.213,124.612-17.335,182.203,8.055L950.933,794.811z"
            />
            <path
              fill="#01D0FB"
              d="M236.332,866.397h184.86c-7.214-18.51-11.215-38.625-11.215-59.685v-52.188
            c-45.72-6.231-92.605,0.192-135.379,19.061l-48.141,21.226v61.71c-0.003,5.451,4.421,9.875,9.874,9.875V866.397z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
