/* eslint-disable function-paren-newline */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-useless-catch */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable object-curly-newline */
/* eslint-disable no-unused-vars */
/* eslint-disable no-return-await */
/* eslint-disable implicit-arrow-linebreak */
import {
  collection,
  getDocs,
  query,
  doc,
  getDoc,
  // addDoc,
  deleteDoc,
  updateDoc,
  where,
  orderBy,
  setDoc,
  addDoc,
  writeBatch,
  onSnapshot,
} from 'firebase/firestore';
import { deleteUser } from 'firebase/auth';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { v4 } from 'uuid';
import { db, firebase } from '../utils/firebase';

const storage = getStorage(firebase);

// eslint-disable-next-line max-len, no-shadow
const getArrayFromCollection = (collection) =>
  // eslint-disable-next-line no-shadow
  collection.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

// SEND IMG AND RETURN YUOR URL
export const uploadFile = async (file, name) => {
  try {
    const storageRef = ref(storage, `products/${v4()}`);
    await uploadBytes(storageRef, file);
    const url = await getDownloadURL(storageRef);
    return url;
  } catch (error) {
    return error;
  }
};

// CREATE ACOUNT AND ID DB
export const createItem = async (obj, collections, id) => {
  const docRef = doc(db, collections, id);
  const data = await setDoc(docRef, obj, { merge: true });
  return data;
};

// CREATE NEW OBJECT IN COLLECTION
export const createItemCustom = async (obj, collections) => {
  const data = await addDoc(collection(db, collections), {
    ...obj,
  });
  return data.id;
};

// UPDATE
export const updateItem = async (id, obj, collections) => {
  const colRef = collection(db, collections);
  await updateDoc(doc(colRef, id), obj);
};

// READ
export const getItems = async (item) => {
  const colRef = collection(db, item);
  const result = await getDocs(query(colRef));
  return getArrayFromCollection(result);
};
// READ ID Collections
export const getItemsByIdCollection = async (value, collections, param) => {
  const colRef = collection(db, collections);
  const result = await getDocs(query(colRef));
  const arraysIdColletion = getArrayFromCollection(result);
};

// READ WITH WHERE
export const getItemsByCondition = async (value, collections, param) => {
  const colRef = collection(db, collections);
  const result = await getDocs(
    query(colRef, where(param, '==', value), orderBy('position', 'asc')),
  );
  return getArrayFromCollection(result);
};

export const getItemsByConditionGuest = async (value, collections, param) => {
  const colRef = collection(db, collections);
  const result = await getDocs(query(colRef, where(param, '==', value)));
  return getArrayFromCollection(result);
};

export const getProductsValidationExist = async (
  collections, restaurantId, menuId, categoryId, name) => {
  const colRef = collection(db, collections);
  const result = await getDocs(query(colRef,
    where('restaurantId', '==', restaurantId),
    where('menuId', '==', menuId),
    where('categoryId', '==', categoryId),
    where('name', '==', name),
  ));
  return getArrayFromCollection(result);
};

export const getItemsByConditionGuestAdmin = async (value, collections, param) => {
  const colRef = collection(db, collections);
  const result = await getDocs(query(colRef, where(param, '==', value)));
  const data = getArrayFromCollection(result);
  return data?.sort((a, b) => b?.lastVisit - a?.lastVisit);
};

// SNAPSHOTS

export const getItemsByConditionGuestAdminSnapshot = async (
  value,
  collections,
  param,
  returnCallback,
) => {
  const collectionRef = collection(db, collections);

  onSnapshot(query(collectionRef, where(param, '==', value)), (snap) => {
    const arrayData = [];

    snap.docs.map((item) =>
      arrayData.push({
        id: item.id,
        ...item.data(),
      }),
    );

    returnCallback(arrayData);
  });
};

export const getItemsByConditionProduct = async (value, collections, param) => {
  const colRef = collection(db, collections);
  const result = await getDocs(
    query(
      colRef,
      where(param, '==', value),
      where('deleted', '==', false),
      orderBy('position', 'asc'),
    ),
  );
  const data = getArrayFromCollection(result);
  return data?.sort((a, b) => a.positionInCategory - b.positionInCategory);
};

export const getItemById = async (id, collections) => {
  const colRef = collection(db, collections);
  const result = await getDoc(doc(colRef, id));
  return result.data();
};

export const getItemByIdProduct = async (id, collections) => {
  const colRef = collection(db, collections);
  const result = await getDoc(doc(colRef, id));
  return { ...result?.data(), id: result?.id };
};

// DELETE
export const deleteItem = async (id, collections) => {
  const colRef = collection(db, collections);
  await deleteDoc(doc(colRef, id));
};

export const deleteItemAndUser = async (id, collections) => {
  const colRef = collection(db, collections);
  await deleteDoc(doc(colRef, id));
  await deleteUser(id);
};

// INTEGRACION DE VALORES A UNA COLECCION
/* const batchSize = 500;

export const updateAllItems = async (item = 'products') => {
  const colRef = collection(db, item);
  const snapshot = await getDocs(query(colRef));

  const totalDocs = snapshot.size;
  const totalBatches = Math.ceil(totalDocs / batchSize);

  let processedDocs = 0;

  for (let i = 0; i < totalBatches; i++) {
    const batch = writeBatch(db);

    const batchDocs = snapshot.docs.slice(i * batchSize, (i + 1) * batchSize);

    batchDocs.forEach((docs) => {
      const docRef = docs.ref;
      batch.set(docRef, { ...docs.data(), position: 0 }, { merge: true });
    });

    await batch.commit();

    processedDocs += batchDocs.length;

    console.log(`Processed ${processedDocs} out of ${totalDocs} documents`);
  }
};
 */
