// Init
import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import VerifyUrl from '../components/VerifyUrl';
import { AuthProvider } from '../context/authContext';
import ProtectedRoute from './ProtectedRoute';

// Routes
import LoginOne from '../view/publicRoute/Login/index';
/* import RegisterOne from '../view/publicRoute/Register/index'; */
// eslint-disable-next-line import/no-named-as-default
import ForgotPassword from '../view/publicRoute/Register/ForgotPass';
import ResetPassword from '../view/publicRoute/Register/ResetPassword';

// import MenuAdmin from '../view/privateRoute/MenuAdmin';
// Menu
import MenuItems from '../view/privateRoute/menuProducts/product-list/Menu';
import MenuLinks from '../view/privateRoute/menuProducts/product-list/MenuLinks';
import MenuEdit from '../view/privateRoute/menuProducts/ProductForm/EditProducts/Menu/index';
import MenuCreate from '../view/privateRoute/menuProducts/ProductForm/CreateProducts/Menu/index';
// Category
import CategoryItems from '../view/privateRoute/menuProducts/product-list/Category';
import CategoryCreate from '../view/privateRoute/menuProducts/ProductForm/CreateProducts/Category/index';
import CategoryEdit from '../view/privateRoute/menuProducts/ProductForm/EditProducts/Category/index';
// Product
import ProductItems from '../view/privateRoute/menuProducts/product-list/Products';
import ProductCreate from '../view/privateRoute/menuProducts/ProductForm/CreateProducts/Products/index';
import ProductEdit from '../view/privateRoute/menuProducts/ProductForm/EditProducts/Products/index';

// Staff
import Staff from '../view/privateRoute/Staff';
import InvitedStaff from '../view/privateRoute/staffInvite/index';

// Guest
import Guest from '../view/privateRoute/GuestManager';
import ProfilGuest from '../view/privateRoute/ProfilGuest';
// import GuestProfile from '../view/privateRoute/GuestProfile';

// Log
import Log from '../view/privateRoute/Log';

function Index() {
  useEffect(() => {}, []);
  return (
    <AuthProvider>
      <Routes>
        <Route path="/" element={<LoginOne />} />
        {/* <Route path="/register" element={<RegisterOne />} /> */}
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/resetPassword" element={<ResetPassword />} />

        <Route element={<ProtectedRoute />}>
          {/* PATH THE MENU */}
          <Route path="menu" element={<MenuItems />} />
          <Route path="menu/links/:id" element={<MenuLinks />} />
          <Route path="menu/edit-menu/:id/:name" element={<MenuEdit />} />
          <Route path="menu/create-menu/:id" element={<MenuCreate />} />
          {/* PATH THE CATEGORY */}
          <Route path="menu/:idMenu/category" element={<CategoryItems />} />
          <Route path="menu/:idMenu/category/edit-category/:id/:name" element={<CategoryEdit />} />
          <Route
            path="menu/:idMenu/category/create-category/:idRestaurant"
            element={<CategoryCreate />}
          />
          {/* PATH THE PRODUCT */}
          <Route path="menu/:idMenu/category/:idCategory" element={<ProductItems />} />
          <Route
            path="menu/:idMenu/category/:idCategory/edit-produt/:idRestaurant/:id"
            element={<ProductEdit />}
          />
          <Route
            path="menu/:idMenu/category/:idCategory/create-produt/:idRestaurant"
            element={<ProductCreate />}
          />
          {/* PATH THE STAFF */}
          <Route path="staff" element={<Staff />} />
          <Route path="staff/invited-staff" element={<InvitedStaff />} />
          {/* PATH THE GUEST */}
          <Route path="guest" element={<Guest />} />
          <Route path="/:id/guest" element={<ProfilGuest />} />
          {/* PATH THE LOG */}
          <Route path="log" element={<Log />} />
          {/* PATH THE DELIVERY */}
        </Route>

        <Route path="*" element={<VerifyUrl />} />
      </Routes>
    </AuthProvider>
  );
}
export default Index;
