/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable radix */
import React, { useState, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Tabs, Form, Button, message,
} from 'antd';
import useMediaQuery from '@mui/material/useMediaQuery';
import EditCategory from './EditCategory';
import Flex from '../../../../../../components/shared-components/Flex';
import { PageHeaderAlt } from '../../../../../../components/layout-components/PageHeaderAlt';
import { updateItem } from '../../../../../../api/api';

function EditMenu() {
  const paramIdRestaurant = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [name, setName] = useState(paramIdRestaurant?.name);
  const matches = useMediaQuery('(max-width: 699px)');
  const [themeInputs, setThemeInputs] = useState();
  const [themeCell, setThemeCell] = useState();
  const { headerNavColor, currentTheme } = useSelector((state) => state.theme);
  const isDarkTheme = currentTheme === 'dark';

  const colorText = useMemo(() => {
    if (isDarkTheme) {
      setThemeInputs('#1b2531');
      setThemeCell('rgb(0 0 0)');
      return '#fff';
    }
    setThemeCell('rgb(148 152 163)');
    setThemeInputs('#fafafb');
    return '#455560';
  }, [isDarkTheme]);

  const onFinish = async () => {
    try {
      setSubmitLoading(true);
      await updateItem(paramIdRestaurant?.id, { name }, 'categories');
      setSubmitLoading(false);
      setName('');
      message.success('Item Created');
      navigate(-1);
    } catch (e) {
      setSubmitLoading(false);
      message.error('Error In Item Created');
    }
  };

  const onBack = () => {
    navigate(-1);
  };

  return (
    <Form
      layout="vertical"
      form={form}
      name="advanced_search"
      className="ant-advanced-search-form"
      initialValues={{
        heightUnit: 'cm',
        widthUnit: 'cm',
        weightUnit: 'kg',
      }}
    >
      <PageHeaderAlt className="border-bottom" overlap>
        <div className="container">
          <Flex
            className="py-2"
            mobileFlex={false}
            justifyContent="space-between"
            alignItems="center"
            flexDirection={matches ? 'column' : 'row'}
          >
            <h2 className="mb-3" style={{ color: colorText }}>
              {`Edit Menu: ${paramIdRestaurant?.name}`}
            </h2>
            <div className="mb-3">
              <Button className="mr-2" onClick={() => onBack()}>Discard</Button>
              <Button type="primary" onClick={() => onFinish()} htmlType="submit" loading={submitLoading}>
                Save Changes
              </Button>
            </div>
          </Flex>
        </div>
      </PageHeaderAlt>
      <div className="container">
        <Tabs
          defaultActiveKey="1"
          style={{ marginTop: 60 }}
          items={[
            {
              label: 'General',
              key: '1',
              children: <EditCategory
                setName={setName}
                name={name}
              />,
            },
          ]}
        />
      </div>
    </Form>
  );
}

export default EditMenu;
