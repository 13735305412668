/* eslint-disable react/no-array-index-key */
/* eslint-disable no-undef */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-shadow */
/* eslint-disable react/prefer-stateless-function */
import React, { useEffect, useState, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Row, Col, Card, Avatar, Switch, Input, message,
} from 'antd';
import {
  GlobalOutlined,
  MailOutlined,
  PhoneOutlined,
  SketchOutlined,
  PlusCircleOutlined,
  FormOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box } from '@mui/material';
import { Icon } from '../../components/util-components/Icon';
import { AvatarStatus } from '../../components/shared-components/AvatarStatus';
import { PageHeaderAlt } from '../../components/layout-components/PageHeaderAlt';
import Flex from '../../components/shared-components/Flex';
import { getItems, getItemById, updateItem } from '../../api/api';
import AvatarImage from '../../assets/images/avatar.png';
import ListPoint from '../../assets/icons/ListPoint.svg';

function ProfileInfo({ avatarSize, userData, restaurantInfo }) {
  const navigate = useNavigate();

  const [themeInputs, setThemeInputs] = useState();
  const [themeCell, setThemeCell] = useState();
  const matches = useMediaQuery('(max-width: 699px)');
  const { headerNavColor, currentTheme } = useSelector((state) => state.theme);
  const isDarkTheme = currentTheme === 'dark';

  const colorText = useMemo(() => {
    if (isDarkTheme) {
      setThemeInputs('#1b2531');
      setThemeCell('rgb(0 0 0)');
      return '#fff';
    }
    setThemeCell('rgb(148 152 163)');
    setThemeInputs('#fafafb');
    return 'rgb(0 0 0)';
  }, [isDarkTheme]);

  return (
    <Card>
      <Row
        justify="center"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: '-webkit-center',
        }}
      >
        <Col
          sm={24}
          md={23}
          style={{
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'nowrap',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: '-webkit-center',
          }}
        >
          <div className="d-md-flex" style={{ flexDirection: 'column', alignItems: 'center' }}>
            <div
              className="rounded p-2 bg-white shadow-sm mx-auto"
              style={{ marginTop: '-3.5rem', maxWidth: `${avatarSize + 16}px` }}
            >
              <Avatar shape="square" size={avatarSize} src={AvatarImage} />
            </div>
            <div className="ml-md-4 w-100">
              <Flex
                alignItems="center"
                mobileFlex={false}
                className="mb-3 text-md-left text-center"
                style={{ justifyContent: 'center' }}
              >
                <h2 className="mb-0 mt-md-0 mt-2" style={{ color: colorText }}>{userData?.name}</h2>
              </Flex>
              <Row
                gutter="16"
                style={matches ? {
                  width: 'calc(50vw - -8vh)',
                  display: 'flex',
                  flexWrap: 'wrap',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                } : {
                  width: 'calc(38vw - -47vh / 2)',
                  display: 'flex',
                  flexWrap: 'wrap',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Col xs={24} sm={24} md={8}>
                  <Row className="mb-2">
                    <Col
                      style={matches ? {
                        textAlignLast: 'center',
                      } : {
                        textAlignLast: 'end',
                      }}
                      xs={12}
                      sm={12}
                      md={9}
                    >
                      <Icon type={MailOutlined} className="text-primary font-size-md" />
                    </Col>
                    <Col xs={12} sm={12} md={15}>
                      <span className="font-weight-semibold" style={{ marginRight: '31px' }}>{userData?.email || ' - '}</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      style={matches ? {
                        textAlignLast: 'center',
                      } : {
                        textAlignLast: 'end',
                      }}
                      xs={12}
                      sm={12}
                      md={9}
                    >
                      <Icon type={PhoneOutlined} className="text-primary font-size-md" />
                    </Col>
                    <Col xs={12} sm={12} md={15}>
                      <span className="font-weight-semibold">{userData?.phone || ' - '}</span>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Row className="mb-2 mt-2 mt-md-0 ">
                    <Col
                      style={matches ? {
                        textAlignLast: 'center',
                      } : {
                        textAlignLast: 'center',
                      }}
                      xs={12}
                      sm={12}
                      md={9}
                    >
                      <Icon type={SketchOutlined} className="text-primary font-size-md" />
                    </Col>
                    <Col
                      style={matches ? {
                        textAlignLast: 'center',
                      } : {
                        textAlignLast: 'start',
                      }}
                      xs={12}
                      sm={12}
                      md={15}
                    >
                      <span className="font-weight-semibold">Guest</span>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col
                      style={matches ? {
                        textAlignLast: 'center',
                      } : {
                        textAlignLast: 'center',
                      }}
                      xs={12}
                      sm={12}
                      md={9}
                    >
                      <Icon type={GlobalOutlined} className="text-primary font-size-md" />
                    </Col>
                    <Col
                      style={matches ? {
                        textAlignLast: 'center',
                      } : {
                        textAlignLast: 'start',
                      }}
                      xs={12}
                      sm={12}
                      md={15}
                    >
                      <span className="font-weight-semibold">{restaurantInfo?.restaurantName || ''}</span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </Card>
  );
}

function Experiences({
  userData,
  setUserData,
  editNoteId,
  setEditNoteIde,
  note,
  setNote,
  id,
}) {
  const handleClick = async () => {
    try {
      if (!userData?.listNotes) {
        const obj = {
          ...userData,
          listNotes: [{
            id: 0,
            note,
          }],
        };
        await updateItem(id, obj, 'guests');
        message.success('Item successfully added');
        setUserData(obj);
        setNote();
        setEditNoteIde();
        return;
      }

      if (editNoteId !== undefined) {
        const updateObj = userData?.listNotes.find((id) => id?.id === editNoteId);
        updateObj.note = note;
        await updateItem(id, userData, 'guests');
        message.success('Item successfully added');
        setUserData(userData);
        setNote();
        setEditNoteIde();
        return;
      }

      const countId = userData?.listNotes.length;
      const obj = {
        ...userData,
        listNotes: [...userData?.listNotes, {
          id: countId,
          note,
        }],
      };
      await updateItem(id, obj, 'guests');
      message.success('Item successfully added');
      setUserData(obj);
      setNote();
      setEditNoteIde();
    } catch (err) {
      message.error('An error occurred while trying to add the note, please try again');
      return err;
    }
  };

  const handleNoteChange = (e) => {
    e.preventDefault();
    setNote(e.target.value);
  };
  return (
    <Card title="Add Note">
      <div className="mb-3">
        <div style={{ textAlignLast: 'end' }}>
          <IconButton
            style={{
              color: '#fff',
              padding: 2,
            }}
            onClick={handleClick}
          >
            <PlusCircleOutlined style={{ color: '#009f1c' }} />
          </IconButton>
        </div>
        <Row className="mb-2">
          <Input.TextArea
            name={['user', 'introduction']}
            label="Introduction"
            onChange={handleNoteChange}
            value={note || ''}
          />
        </Row>
      </div>
    </Card>
  );
}

function Interested({
  userData,
  setUserData,
  editNoteId,
  setEditNoteIde,
  note,
  setNote,
  id,
}) {
  const handleEditNote = (id, note) => {
    setNote(note);
    setEditNoteIde(id);
  };

  const handleDelete = async (ids, note) => {
    try {
      if (userData?.listNotes.length === 1) {
        const newObj = {
          ...userData,
          listNotes: [],
        };
        await updateItem(id, newObj, 'guests');
        message.success('Item Deleted');
        setUserData(newObj);
        setNote();
        setEditNoteIde();
        return;
      }
      let newObj = [];
      const filtObj = await userData?.listNotes.filter((item) => item?.id !== ids);
      await filtObj.forEach((item, i) => {
        newObj = {
          ...userData,
          listNotes: [...newObj?.listNotes || '',
            {
              note: item?.note,
              id: i,
            },
          ],
        };
      });
      await updateItem(id, newObj, 'guests');
      message.success('Item Deleted');
      setUserData(newObj);
      setNote();
      setEditNoteIde();
    } catch (err) {
      message.error('Something happened while trying to delete the note, try again');
      return err;
    }
  };
  return (
    <Card title="Notes">
      <div>
        <Col>
          {userData?.listNotes?.length !== 0 ? userData?.listNotes?.map((item) => (
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '2rem' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                }}
              >
                <Box component="img" src={ListPoint} />
                <p>{item?.note}</p>
              </div>
              <div
                style={{
                  textAlignLast: 'end',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <IconButton
                  style={{
                    color: '#fff',
                    padding: 2,
                  }}
                  onClick={() => handleEditNote(item?.id, item?.note)}
                >
                  <FormOutlined style={{ color: 'rgb(79 108 255)' }} />
                </IconButton>
                <IconButton
                  style={{
                    color: '#fff',
                    padding: 2,
                  }}
                  onClick={() => handleDelete(item?.id, item?.note)}
                >
                  <DeleteOutlined style={{ color: 'rgb(188 59 59)' }} />
                </IconButton>
              </div>
            </div>
          )) : (
            <p>This user does not have any notes yet</p>
          )}
        </Col>
      </div>
    </Card>
  );
}

function Connection({ userData }) {
  const onChange = (onChange) => {
  };
  return (
    <Card title="Guest Control">
      <Col xs={24} sm={24} md={8}>
        <Row className="mb-2" style={{ width: '26rem' }}>
          <Col xs={12} sm={12} md={9}>
            <span className="text-muted ml-2">LST Visit:</span>
          </Col>
          <Col xs={12} sm={12} md={15} style={{ textAlign: '-webkit-center' }}>
            <span className="font-weight-semibold">
              {new Date(userData?.lastVisit?.seconds * 1000)
                .toString()
                .split(' ', 4)
                .join(' ')}

            </span>
          </Col>
        </Row>
        <Row style={{ width: '26rem', marginBottom: '1rem' }}>
          <Col xs={12} sm={12} md={9}>
            <span className="text-muted ml-2">VIP:</span>
          </Col>
          <Col xs={12} sm={12} md={15} style={{ textAlign: '-webkit-center' }}>
            <Switch defaultChecked onChange={onChange} />
          </Col>
        </Row>
        <Row style={{ width: '26rem', marginBottom: '1rem' }}>
          <Col xs={12} sm={12} md={9}>
            <span className="text-muted ml-2">Black List:</span>
          </Col>
          <Col xs={12} sm={12} md={15} style={{ textAlign: '-webkit-center' }}>
            <Switch defaultChecked onChange={onChange} />
          </Col>
        </Row>
        <Row style={{ width: '26rem', marginBottom: '1rem' }}>
          <Col xs={12} sm={12} md={9}>
            <span className="text-muted ml-2">TL Spend:</span>
          </Col>
          <Col xs={12} sm={12} md={15} style={{ textAlign: '-webkit-center' }}>
            <span className="font-weight-semibold"> - </span>
          </Col>
        </Row>
        <Row style={{ width: '26rem', marginBottom: '1rem' }}>
          <Col xs={12} sm={12} md={9}>
            <span className="text-muted ml-2">TL Visits:</span>
          </Col>
          <Col xs={12} sm={12} md={15} style={{ textAlign: '-webkit-center' }}>
            <span className="font-weight-semibold">{userData?.numberOfVisits}</span>
          </Col>
        </Row>
      </Col>
    </Card>
  );
}

function Group() {
  return (
    <Card title="Group">
      {groupList.map((elm, i) => (
        <div className={`${i === groupList.length - 1 ? '' : 'mb-4'}`} key={`connection-${i}`}>
          <AvatarStatus src={elm.img} name={elm.name} subTitle={elm.desc} />
        </div>
      ))}
    </Card>
  );
}

export function Profile() {
  const avatarSize = 150;
  const { id } = useParams();
  const [userData, setUserData] = useState(null);
  const [restaurantInfo, setRestaurantInfo] = useState(null);
  const [editNoteId, setEditNoteIde] = useState();
  const [note, setNote] = useState();

  useEffect(() => {
    const getUserData = async () => {
      try {
        const data = await getItemById(id, 'guests');
        return setUserData(data);
      } catch (err) {
        return err;
      }
    };

    const GetDataRestaurant = async () => {
      try {
        const data = await getItems('restaurants');
        const tokenId = localStorage.getItem('token');
        const rest = data?.filter((item) => item?.id === tokenId);
        const [objRest] = rest;
        return setRestaurantInfo(objRest);
      } catch (err) {
        return err;
      }
    };

    getUserData();
    GetDataRestaurant();
  }, [id]);

  return (
    <>
      <PageHeaderAlt
        background={restaurantInfo?.landingImage || ''}
        cssClass="bg-primary"
        overlap
      >
        <div className="container text-center">
          <div className="py-5 my-md-5" />
        </div>
      </PageHeaderAlt>
      <div className="container my-4">
        <ProfileInfo avatarSize={avatarSize} userData={userData} restaurantInfo={restaurantInfo} />
        <Row gutter="16">
          <Col xs={24} sm={24} md={8}>
            <Connection userData={userData} />
            {/* <Group /> */}
          </Col>
          <Col xs={24} sm={24} md={16}>
            <Experiences
              userData={userData}
              setUserData={setUserData}
              editNoteId={editNoteId}
              setEditNoteIde={setEditNoteIde}
              note={note}
              setNote={setNote}
              id={id}
            />
            <Interested
              userData={userData}
              setUserData={setUserData}
              editNoteId={editNoteId}
              setEditNoteIde={setEditNoteIde}
              note={note}
              setNote={setNote}
              id={id}
            />
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Profile;
