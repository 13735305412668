import React, { useState } from 'react';
import {
  Card, Row, Col, Form, Input, Button, message,
} from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../context/authContext';
import BackgroundImg from '../../../assets/images/login/img-17.jpg';
import Powered from '../../../assets/images/powered.png';

function ForgotPassword() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleRedirect = (route) => {
    setTimeout(() => {
      navigate(route);
    }, 2000);
  };

  const { resetPassword } = useAuth();

  const handleLoginUser = async (data) => {
    setLoading(true);
    await resetPassword(data.email)
      .then(() => {
        setLoading(false);
        message.success('New password has send to your email!');
        handleRedirect('/');
      })
      .catch(() => {
        setLoading(false);
        message.error('The email does not exist or is misspelled');
      });
  };

  return (
    <div
      className="h-100"
      style={{
        backgroundImage: `url(${BackgroundImg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: '100%',
      }}
    >
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        justifyContent: 'center',
        alignItems: 'stretch',
        height: '100%',
      }}
      >
        <Row justify="center">
          <Col xs={20} sm={20} md={20} lg={9}>
            <Card>
              <div className="my-2">
                <div style={{ textAlign: 'center' }}>
                  <img
                    className="img-fluid"
                    src={`${Powered}`}
                    alt=""
                  />
                  <h3
                    style={{
                      marginTop: '3px',
                      fontWeight: 'bold',
                    }}
                  >
                    Forgot Password?
                  </h3>
                  <p style={{ marginBottom: '4px' }}>Enter your Email to reset password</p>
                </div>
                <Row justify="center">
                  <Col xs={24} sm={24} md={20} lg={20}>
                    <Form form={form} layout="vertical" name="forget-password" onFinish={handleLoginUser}>
                      <Form.Item
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: 'Please input your email address',
                          },
                          {
                            type: 'email',
                            message: 'Please enter a validate email!',
                          },
                        ]}
                      >
                        <Input
                          placeholder="Email Address"
                          prefix={<MailOutlined className="text-primary" />}
                        />
                      </Form.Item>
                      <Form.Item>
                        <Button loading={loading} type="primary" htmlType="submit" block>
                          {loading ? 'Sending' : 'Send'}
                        </Button>
                      </Form.Item>
                    </Form>
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}
export default ForgotPassword;
