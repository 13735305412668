/* eslint-disable no-shadow */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable radix */
import React, { useState, useMemo, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Tabs, Form, Button, message, Input, Row, Col, Card, Checkbox,
} from 'antd';
import useMediaQuery from '@mui/material/useMediaQuery';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import { Buffer } from 'buffer';
import InvitedStaff from './InvitedStaff';
import Flex from '../../../components/shared-components/Flex';
import { PageHeaderAlt } from '../../../components/layout-components/PageHeaderAlt';
import { createItemCustom, getItemsByConditionGuest } from '../../../api/api';
import { useAuth } from '../../../context/authContext';

const rules = {
  name: [
    {
      required: true,
      message: 'Please enter name',
    },
  ],
  email: [
    {
      required: true,
      message: 'Please enter email',
    },
  ],
  password: [
    {
      required: true,
      message: 'Please enter password',
    },
  ],
};

function ProductForm() {
  const paramIdRestaurant = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const {
    user, userID, inviteUser, secountGuestUser,
  } = useAuth();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [restaurant, setRestaurant] = useState();
  const matches = useMediaQuery('(max-width: 699px)');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [seleCheck, setSelectCheck] = useState('');
  const [themeInputs, setThemeInputs] = useState();
  const [themeCell, setThemeCell] = useState();
  const [link, setLink] = useState('');
  const [token, setToken] = useState('');
  const { headerNavColor, currentTheme } = useSelector((state) => state.theme);
  const isDarkTheme = currentTheme === 'dark';

  const colorText = useMemo(() => {
    if (isDarkTheme) {
      setThemeInputs('#1b2531');
      setThemeCell('rgb(0 0 0)');
      return '#fff';
    }
    setThemeCell('rgb(148 152 163)');
    setThemeInputs('#fafafb');
    return '#455560';
  }, [isDarkTheme]);

  const fetchDataRestaurant = async () => {
    const data = await getItemsByConditionGuest(user.email, 'restaurants', 'email');
    setRestaurant(...data);
  };

  useEffect(() => {
    fetchDataRestaurant();
  }, []);

  const onFinish = async () => {
    setSubmitLoading(true);
    const tokenUser = localStorage.getItem('token');
    const tokenID = userID || tokenUser;
    const objectUser = {
      email,
      invite: true,
      link,
      nameInvited: name,
      restaurantId: tokenID,
      restaurantName: restaurant?.restaurantName,
      rol: seleCheck,
      token,
    };
    delete objectUser.password;
    const response = await inviteUser(email, password, objectUser);

    /* if (response === 'auth/email-already-in-use') {
      console.log('entra');
      await secountGuestUser(email, password, objectUser);
      setSubmitLoading(false);
      setTimeout(() => {
        setLink('');
        setToken('');
        message.success('Invitation sent');
        navigate(-1);
      }, 1000);
      return;
    } */

    setSubmitLoading(false);
    setTimeout(() => {
      setLink('');
      setToken('');
      message.success('Invitation sent');
      navigate(-1);
    }, 1000);
  };

  const onBack = () => {
    navigate(-1);
  };

  const handleGeneratorTokenAndLink = async (e, name, email) => {
    setSelectCheck(e);
    const tokenString = Buffer.from(`${name}-${email}`).toString('base64');
    setToken(tokenString);
    setLink(`mynuutheapp.com/${restaurant?.restaurantName}/${tokenString}`);
  };

  return (
    <Form
      layout="vertical"
      form={form}
      name="advanced_search"
      className="ant-advanced-search-form"
      initialValues={{
        heightUnit: 'cm',
        widthUnit: 'cm',
        weightUnit: 'kg',
      }}
    >
      <PageHeaderAlt className="border-bottom" overlap>
        <div className="container">
          <Flex className="py-2" mobileFlex={false} justifyContent="space-between" alignItems="center">
            <h2 className="mb-3" style={{ color: colorText }}>
              New Staff
              {' '}
            </h2>
            <div className="mb-3">
              <Button className="mr-2" onClick={() => onBack()}>Discard</Button>
              <Button type="primary" onClick={() => onFinish()} htmlType="submit" loading={submitLoading}>
                Invite
              </Button>
            </div>
          </Flex>
        </div>
      </PageHeaderAlt>
      <div className="container">
        <Tabs
          defaultActiveKey="1"
          style={{ marginTop: 60 }}
          items={[
            {
              label: 'General',
              key: '1',
              children: (
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={17}>
                    <Card>
                      <Form.Item name="name" label="name" rules={rules.name}>
                        <Input placeholder="Name" onChange={(e) => setName(e?.target?.value)} />
                      </Form.Item>
                      <Form.Item name="email" label="email" rules={rules.email}>
                        <Input prefix={<MailOutlined style={{ color: '#007bff' }} />} placeholder="Email" onChange={(e) => setEmail(e?.target?.value)} />
                      </Form.Item>
                      <Form.Item name="password" label="password" rules={rules.password}>
                        <Input.Password prefix={<LockOutlined style={{ color: '#007bff' }} />} placeholder="Password" onChange={(e) => setPassword(e?.target?.value)} />
                      </Form.Item>
                      <div
                        style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
                      >
                        <h5 style={{ alignSelf: 'center' }}>Account Tier</h5>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-evenly',
                            flexWrap: 'nowrap',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              justifyContent: 'space-evenly',
                              flexWrap: 'nowrap',
                            }}
                          >
                            <Checkbox
                              color="primary"
                              onChange={(e) => {
                                setLink('');
                                setToken('');
                                setSelectCheck(e?.target?.value);
                              }}
                              checked={seleCheck === 'Manager'}
                              value="Manager"
                              sx={{ padding: 1, color: '#fff' }}
                            />
                            <p style={{ marginLeft: '1rem' }}>Manager</p>
                          </div>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Checkbox
                              color="primary"
                              onChange={(e) => handleGeneratorTokenAndLink(e?.target?.value, name, email)}
                              checked={seleCheck === 'Staff'}
                              value="Staff"
                              sx={{ padding: 1, color: '#fff' }}
                            />
                            <p style={{ marginLeft: '1rem' }}>Staff</p>
                          </div>
                        </div>
                      </div>
                      { link !== '' && (
                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                        <p style={matches ? { fontSize: 'xx-small' } : { fontSize: 'x-small' }}>{link}</p>
                      </div>
                      )}
                    </Card>
                  </Col>
                </Row>
              ),
            },
          ]}
        />
      </div>
    </Form>
  );
}

export default ProductForm;
