/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo } from 'react';
import { Card, Table, Select, Input, Menu, message, Button } from 'antd';
import { SearchOutlined, EyeOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import utils from '../../../../../../utils';
import Flex from '../../../../../../components/shared-components/Flex';
import { getItemsByConditionGuestAdmin, updateItem } from '../../../../../../api/api';
import { useAuth } from '../../../../../../context/authContext';

const paymentStatusList = ['Manager', 'Staff'];

function LogInventory() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [allData, setAllData] = useState([]);
  const [list, setList] = useState(allData);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [buttonDeleteLoad, setButtonDeleteLoad] = useState(false);
  const [id, setId] = useState();
  const [band, setBand] = useState(false);
  const paramIdRestaurant = useParams();
  const [themeInputs, setThemeInputs] = useState();
  const [themeCell, setThemeCell] = useState();
  const { headerNavColor, currentTheme } = useSelector((state) => state.theme);
  const isDarkTheme = currentTheme === 'dark';

  const colorText = useMemo(() => {
    if (isDarkTheme) {
      setThemeInputs('#1b2531');
      setThemeCell('rgb(0 0 0)');
      return '#fff';
    }
    setThemeCell('rgb(148 152 163)');
    setThemeInputs('#fafafb');
    return '#455560';
  }, [isDarkTheme]);

  const dataUserRestaurant = async () => {
    try {
      const data = await getItemsByConditionGuestAdmin(user?.uid, 'logInventory', 'idRestaurant');
      const filteredLogInventory = data
        .flatMap((entry) => entry.log)
        .filter((log) => log.idItem === paramIdRestaurant.id);
      setAllData(filteredLogInventory);
      setList(filteredLogInventory);
    } catch (err) {
      return err;
    }
  };
  useEffect(() => {
    dataUserRestaurant();
  }, [paramIdRestaurant.id]);

  const tableColumns = [
    {
      title: 'Type',
      dataIndex: 'type',
      sorter: (a, b) => utils.antdTableSorter(a, b, 'type'),
      width: 3,
    },
    {
      title: 'Responsible',
      dataIndex: 'user',
      sorter: (a, b) => utils.antdTableSorter(a, b, 'user'),
      width: 2,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      sorter: (a, b) => utils.antdTableSorter(a, b, 'email'),
      width: 3,
    },
    {
      title: 'Item Change',
      dataIndex: 'nameItem',
      sorter: (a, b) => utils.antdTableSorter(a, b, 'nameItem'),
      width: 2,
    },
    {
      title: 'Date edited',
      dataIndex: 'time',
      render: (_, record) => {
        const timesTampInMilliseconds = record?.time?.seconds * 1000 + record?.time?.nanoseconds / 1000000;
        const date = new Date(timesTampInMilliseconds);
        const hour = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();
        const amOrpm = hour > 12 ? 'pm' : 'am';
        return (
          <div>
            {new Date(record?.time?.seconds * 1000).toString().split(' ', 4).join(' ')}
            <p>
              {hour}
              :
              {minutes}
              :
              {seconds}
              {amOrpm}
            </p>
          </div>
        );
      },
      width: 7,
      /* sorter: (a, b) => utils.antdTableSorter(a, b, 'time'), */
    },
    {
      title: 'New Values',
      dataIndex: 'newDate',
      render: (_, record) => (
        <div>
          {record?.newData?.map((item, index) => (
            <div key={index}>
              <p>
                Quantity:
                {item.quantity}
              </p>
              <p>
                TypeBottles:
                {item.typeBottles}
              </p>
            </div>
          ))}
        </div>
      ),
      width: 8,
    },
    {
      title: 'Old Values',
      dataIndex: 'oldData',
      render: (_, record) => (
        <div>
          {Array.isArray(record?.oldData)
            ? record.oldData.map((item, index) => (
              <div key={index}>
                <p>
                  Quantity:
                  {item.quantity}
                </p>
                <p>
                  TypeBottles:
                  {item.typeBottles}
                </p>
              </div>
            ))
            : null}
        </div>
      ),
      width: 5,
    },
  ];

  const onSearch = (e) => {
    const { value } = e.currentTarget;
    const searchArray = e.currentTarget.value ? list : allData;
    const data = utils.wildCardSearch(searchArray, value);
    setList(data);
    setSelectedRowKeys([]);
  };

  return (
    <Card style={{ width: '100%' }}>
      <Flex alignItems="center" justifyContent="space-between" mobileFlex={false}>
        <h3 style={{ color: colorText }}>Log Inventory</h3>
        <Flex className="mb-1" mobileFlex={false}>
          <div className="mr-md-3 mb-3">
            <Input placeholder="Search" prefix={<SearchOutlined />} onChange={(e) => onSearch(e)} />
          </div>
        </Flex>
      </Flex>
      <div className="table-responsive">
        <Table
          columns={tableColumns}
          dataSource={list}
          rowKey="id"
          scroll={{
            y: 450,
            x: '163vw',
          }}
        />
      </div>
    </Card>
  );
}

export default LogInventory;
