/* eslint-disable linebreak-style */
/* eslint-disable operator-linebreak */
/* eslint-disable linebreak-style */
/* eslint-disable max-len */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/prefer-default-export */
import dayjs from 'dayjs';
import utils from '../../utils';

// COLUMNS ORDER HISTORY

export const TABLE_COLUMNS = [
  {
    title: 'Quantity',
    dataIndex: 'quantityBottles',
    sorter: (a, b) => utils.antdTableSorter(a, b, 'quantityBottles'),
    width: 1,
  },
  {
    title: 'Cases',
    dataIndex: 'quantityCases',
    sorter: (a, b) => utils.antdTableSorter(a, b, 'quantityCases'),
    width: 1,
  },
  {
    title: 'Request',
    dataIndex: 'requestDate',
    render: (_, record) => {
      const timesTampInMilliseconds =
        record?.requestDate?.seconds * 1000 + record.requestDate?.nanoseconds / 1000000;
      const date = new Date(timesTampInMilliseconds);
      const hour = date.getHours();
      const minutes = date.getMinutes();
      const seconds = date.getSeconds();
      const amOrpm = hour > 12 ? 'pm' : 'am';
      return (
        <div>
          {new Date(record?.requestDate?.seconds * 1000).toString().split(' ', 4).join(' ')}
          <p>
            {hour}:{minutes}:{seconds} {amOrpm}
          </p>
        </div>
      );
    },
    width: 2,
  },
  {
    title: 'Delivery',
    dataIndex: 'deliveryDate',
    sorter: (a, b) => utils.antdTableSorter(a, b, 'deliveryDate'),
    width: 2,
    render: (_, record) => <div>{dayjs(record.deliveryDate).format('DD/MM/YYYY')}</div>,
  },

  {
    title: 'Status',
    dataIndex: 'status',
    width: 2,
  },
];
