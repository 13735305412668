/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useMemo } from 'react';
import { Card, Table } from 'antd';
import { useSelector } from 'react-redux';

import Flex from '../shared-components/Flex';

import { TABLE_COLUMNS } from '../../helpers/Delivery';
import { useOrderHistory } from './hooks';

function OrderHistory({ productData }) {
  const { list } = useOrderHistory({ productData });
  const [themeInputs, setThemeInputs] = useState();
  const [themeCell, setThemeCell] = useState();
  const { headerNavColor, currentTheme } = useSelector((state) => state.theme);
  const isDarkTheme = currentTheme === 'dark';

  const colorText = useMemo(() => {
    if (isDarkTheme) {
      setThemeInputs('#1b2531');
      setThemeCell('rgb(0 0 0)');
      return '#fff';
    }
    setThemeCell('rgb(148 152 163)');
    setThemeInputs('#fafafb');
    return '#455560';
  }, [isDarkTheme]);

  return (
    <Card style={{ width: '100%' }}>
      <Flex alignItems="center" justifyContent="space-between" mobileFlex={false}>
        <h3 style={{ color: '#455560' }}>Order History</h3>
      </Flex>
      <div className="table-responsive">
        <Table
          columns={TABLE_COLUMNS}
          dataSource={list}
          rowKey="id"
          scroll={{
            y: 450,
            x: '85vw',
          }}
        />
      </div>
    </Card>
  );
}

export default OrderHistory;
