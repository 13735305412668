/* eslint-disable no-useless-catch */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import {
  Button, Form, Input, Divider, Alert,
} from 'antd';
import { motion } from 'framer-motion';
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
/* import { GoogleSVG } from '../../../assets/svg/icon'; */
import { useAuth } from '../../../context/authContext';
/* import CustomIcon from '../../../components/CustomIcon/index'; */

function LoginAdmin() {
  const [message, setMessage] = useState('');
  const [errorMngs, setErrorMngs] = useState(false);
  const [loading, setLoading] = useState(false);
  const otherSignIn = true;
  const navigate = useNavigate();
  const initialValues = {
    email: '',
    password: '',
  };

  const notify = (item) => {
    toast.error(item, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const { login /* signInWithGoogle */ } = useAuth();

  const handleRedirect = (route) => {
    navigate(route);
  };

  const messageError = (messages) => {
    setErrorMngs(true);
    if (messages.includes('auth/user-not-found')) {
      return setMessage('User Not Found');
    }
    if (messages.includes('auth/wrong-password')) {
      return setMessage('Invalid Password');
    }
    return setMessage('This user does not have an account');
  };

  const handleLoginUser = async (data) => {
    setErrorMngs(false);
    setLoading(true);
    try {
      const res = await login(data.email, data.password, data);
      if (res) {
        setLoading(false);
        navigate('/menu');
      } else {
        setLoading(false);
        notify('error when logging in');
      }
    } catch (error) {
      messageError(error.message);
      setLoading(false);
    }
  };

  /* const handleGoogleSignin = async () => {
    try {
      await signInWithGoogle();
    } catch (error) {
      throw error;
    }
  }; */

  const renderOtherSignIn = (
    <div>
      <Divider>
        {/* <span
          style={{
            color: '#6c757d',
            opacity: 0.6,
            fontSize: '16px',
            fontWeight: 'normal',
          }}
        >
          or connect with
        </span> */}
      </Divider>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {/* <Button
          onClick={() => handleGoogleSignin()}
          disabled={loading}
          icon={<CustomIcon svg={GoogleSVG} />}
        >
          Google
        </Button> */}
      </div>
    </div>
  );

  return (
    <>
      <motion.div
        initial={{ opacity: 0, marginBottom: 0 }}
        animate={{
          opacity: 1,
          marginBottom: 20,
        }}
      >
        {errorMngs && <Alert style={{ marginTop: 10 }} type="error" showIcon message={message} />}
      </motion.div>
      <Form
        layout="vertical"
        name="login-form"
        initialValues={initialValues}
        onFinish={handleLoginUser}
      >
        <Form.Item
          name="email"
          label={(
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                alignItems: 'center',
              }}
            >
              <span
                style={{
                  color: '#455560',
                  fontSize: '14px',
                }}
              >
                {' '}
                Email
                {' '}
              </span>
            </div>
          )}
          rules={[
            {
              required: true,
              message: 'Please input your email',
            },
            {
              type: 'email',
              message: 'Please enter a validate email!',
            },
          ]}
        >
          <Input prefix={<MailOutlined style={{ color: '#007bff' }} />} />
        </Form.Item>
        <Form.Item
          style={{ marginBottom: 4 }}
          name="password"
          label={(
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                alignItems: 'center',
              }}
            >
              <span
                style={{
                  color: '#455560',
                  fontSize: '14px',
                }}
              >
                {' '}
                Password
                {' '}
              </span>
            </div>
          )}
          rules={[
            {
              required: true,
              message: 'Please input your password',
            },
          ]}
        >
          <Input.Password prefix={<LockOutlined style={{ color: '#007bff' }} />} />
        </Form.Item>
        <div
          style={{
            marginBottom: '15px',
          }}
        >
          <span
            style={{
              cursor: 'pointer',
              fontSize: '12px',
              fontWeight: 'normal',
              color: '#6c757d',
              opacity: 0.6,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
            onClick={() => handleRedirect('forgotPassword')}
            className="cursor-pointer font-size-sm font-weight-normal text-muted"
          >
            Forget Password?
          </span>
        </div>
        <Form.Item
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            type="primary"
            htmlType="submit"
            block
            loading={loading}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            Sign In
          </Button>
        </Form.Item>
        {otherSignIn ? renderOtherSignIn : null}
      </Form>
    </>
  );
}
export default LoginAdmin;
