import React, { Suspense, memo } from 'react';
import { useSelector } from 'react-redux';
import { ConfigProvider } from 'antd';
import Routes from '../routes';
import useBodyClass from '../hooks/useBodyClass';
import { resources } from '../lang';
import { lightTheme, darkTheme } from '../configs/ThemeConfig';
import Loading from '../components/Loading/index';

function Layouts() {
  const locale = useSelector((state) => state.theme.locale);

  const direction = useSelector((state) => state.theme.direction);

  const currentTheme = useSelector((state) => state.theme.currentTheme);

  const currentAppLocale = resources[locale];

  useBodyClass(`dir-${direction}`);

  const themeConfig = currentTheme === 'light' ? { ...lightTheme } : { ...darkTheme };

  return (
    <ConfigProvider theme={themeConfig} direction={direction} locale={currentAppLocale.antd}>
      <Suspense fallback={<Loading cover="content" />}>
        <Routes />
      </Suspense>
    </ConfigProvider>
  );
}

export default memo(Layouts);
