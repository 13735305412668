import React from 'react';

function VerifyUrl() {
  return (
    <div className="verify_url">
      <div className="heading">Oops! We seem to have had a problem with your request</div>
      <div className="description">Please try again or check the route you wish to enter</div>
      <div className="logo">MYNUU</div>
    </div>
  );
}

export default VerifyUrl;
