/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable no-shadow */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import React, { useState, useEffect, useMemo } from 'react';
import {
  Card, Table, Select, Input, Button, Badge, Menu, message,
} from 'antd';
import { useSelector } from 'react-redux';
import {
  EyeOutlined,
  DeleteOutlined,
  SearchOutlined,
  PlusCircleOutlined,
  MenuOutlined,
  LeftOutlined,
  EditOutlined,
} from '@ant-design/icons';
import useMediaQuery from '@mui/material/useMediaQuery';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import {
  List,
  IconButton,
  CircularProgress,
  Menu as MenuMaterial,
  Button as ButtonMaterial,
  MenuItem,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import utils from '../../../../utils/index';
import Flex from '../../../../components/shared-components/Flex';
import EllipsisDropdown from '../../../../components/shared-components/EllipsisDropdown';
import { useAuth } from '../../../../context/authContext';
import {
  getItemsByCondition,
  updateItem,
  getItems,
  getItemsByConditionGuest,
  deleteItem,
} from '../../../../api/api';

const { Option } = Select;

const getStockStatus = (stockCount, colorText) => {
  if (stockCount) {
    return (
      <>
        <Badge status="success" style={{ width: '1rem', height: '1rem' }} />
      </>
    );
  }
  if (stockCount < 10 && stockCount > 0) {
    return (
      <>
        <Badge status="warning" style={{ width: '1rem', height: '1rem' }} />
      </>
    );
  }
  if (!stockCount) {
    return (
      <>
        <Badge status="error" style={{ width: '1rem', height: '1rem' }} />
      </>
    );
  }
  return null;
};

const DragHandle = sortableHandle(({ active }) => <MenuOutlined style={{ cursor: 'grab' }} />);

const SortableItem = sortableElement((props) => <tr {...props} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);

const DraggableContainer = sortableContainer((props) => <tbody {...props} />);

function MenuItems({ setItemSelected }) {
  const navigate = useNavigate();
  const {
    user, idMenu, setIdCategory, idCategory,
  } = useAuth();
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [invitedRestaurants, setInvitedRestaurants] = useState([]);
  const [newOrEditItem, setNewOrEditItem] = useState(false);
  const [idInvited, setIdInvited] = useState('');
  const matches = useMediaQuery('(max-width: 699px)');
  const [menuData, setMenuData] = useState([]);
  const [menu, setMenu] = useState(menuData || []);
  const [dataSource, setDataSource] = useState(menuData);
  const [selectedItems, setSelectedItems] = useState([]);
  const [acty, setActy] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [themeText, setThemeText] = useState();
  const [themeInputs, setThemeInputs] = useState();
  const [themeCell, setThemeCell] = useState();
  const [deleteCategory, setDeleteCategory] = useState();
  const { headerNavColor, currentTheme } = useSelector((state) => state.theme);
  const isDarkTheme = currentTheme === 'dark';

  const colorText = useMemo(() => {
    if (isDarkTheme) {
      setThemeInputs('#1b2531');
      setThemeCell('rgb(0 0 0)');
      return '#fff';
    }
    setThemeCell('rgb(148 152 163)');
    setThemeInputs('#fafafb');
    return '#455560';
  }, [isDarkTheme]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    if (open) {
      dropdownMenu();
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const dataMenu = async () => {
    setLoading(true);
    const data = await getItemsByCondition(idMenu?.id, 'categories', 'menuId');
    setMenu(data);
    setMenuData(data);
    setLoading(false);
  };

  function arrayMove(array, oldIndex, newIndex) {
    if (newIndex >= array.length) {
      let k = newIndex - array.length + 1;
      while (k--) {
        array.push(undefined);
      }
    }
    array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);
    return array;
  }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    let newMenu = [...menu];

    if (oldIndex !== newIndex) {
      if (!selectedItems.length) {
        newMenu = arrayMove(newMenu, oldIndex, newIndex);
      } else {
        const filteredItems = selectedItems.map((index) => newMenu[index]);
        newMenu = newMenu.filter((_, index) => !selectedItems.includes(index));
        newMenu = merge(newMenu, filteredItems, newIndex);
      }

      newMenu.forEach((item, index) => {
        item.position = index + 1;
      });

      newMenu.forEach(async (item, i) => {
        const uid = item?.id;
        await updateItem(uid, { position: i }, 'categories');
      });

      setMenu(newMenu);
      setSelectedItems([]);
    }
  };

  useEffect(() => {
    if (!idMenu?.id) {
      return navigate(-1);
    }
    dataMenu();
  }, [acty, deleteCategory]);

  const addProduct = (idInvited) => {
    navigate(`create-category/${idMenu?.restaurantId}`);
  };

  const onBack = () => {
    navigate(-1);
  };

  const viewDetails = (row) => {
    navigate(`edit-category/${row?.id}/${row?.name}`);
  };

  const deleteRow = async (row) => {
    try {
      setDeleteCategory(true);
      await deleteItem(row?.id, 'categories');
      message.success('Item Deleted');
      setNewOrEditItem(true);
      setDeleteCategory(false);
    } catch (err) {
      message.error('Error in Item Deleted');
      setDeleteCategory(false);
    }
  };

  const dropdownMenu = (row) => (
    <MenuMaterial
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      <MenuItem onClick={() => viewDetails(row)}>
        <Flex alignItems="center">
          <EyeOutlined />
          <span className="ml-2">View Details</span>
        </Flex>
      </MenuItem>
      <MenuItem onClick={() => deleteRow(row)}>
        <Flex alignItems="center">
          <DeleteOutlined />
          <span className="ml-2">
            {selectedRows.length > 0 ? `Delete (${selectedRows.length})` : 'Delete'}
          </span>
        </Flex>
      </MenuItem>
    </MenuMaterial>
  );

  const updateMenu = async (activity, target) => {
    try {
      await updateItem(target.id, { status: !activity }, 'categories');
      message.success(target.status ? 'Item Deactivated' : 'Item Activated');
      setActy(acty === 0 ? activity : !acty);
    } catch (err) {
      message.error('Error in Item Desactivated');
    }
  };

  const handleAction = (stock, target) => {
    setIdCategory(target);
    navigate(`${target?.id}`);
  };

  const tableColumns = [
    {
      title: 'Sort',
      dataIndex: '',
      width: 30,
      className: 'drag-visible',
      render: (d, dd, i) => (
        <>
          <DragHandle active={selectedItems.includes(i)} />
        </>
      ),
    },
    {
      title: 'Name Category',
      dataIndex: 'name',
      sorter: (a, b) => utils.antdTableSorter(a, b, 'name'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (stock, target) => (
        <IconButton
          onClick={() => updateMenu(stock, target)}
          style={{ width: '7rem', minWidth: 0, borderRadius: '14px' }}
        >
          <Flex alignItems="center">{getStockStatus(stock)}</Flex>
        </IconButton>
      ),
      sorter: (a, b) => utils.antdTableSorter(a, b, 'status'),
    },
    {
      title: '',
      dataIndex: 'id',
      render: (stock, target) => (
        <IconButton
          onClick={() => handleAction(stock, target)}
          style={{ width: '10rem', borderRadius: '14px' }}
        >
          <Flex alignItems="center" style={{ color: colorText }}>Go to Products</Flex>
        </IconButton>
      ),
    },
    {
      title: '',
      dataIndex: 'actionsEdit',
      render: (_, row) => (
        <div className="text-right">
          <IconButton
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={() => viewDetails(row)}
          >
            <Flex alignItems="center">
              <EditOutlined style={{ color: colorText }} />
            </Flex>
          </IconButton>
        </div>
      ),
    },
    {
      title: '',
      dataIndex: 'actionsDelete',
      render: (_, row) => (
        <div className="text-right">
          <IconButton
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={() => deleteRow(row)}
          >
            <Flex alignItems="center">
              <DeleteOutlined style={{ color: colorText }} />
            </Flex>
          </IconButton>
        </div>
      ),
    },
  ];

  const merge = (a, b, i = 0) => {
    const aa = [...a];
    return [...a.slice(0, i), ...b, ...aa.slice(i, aa.length)];
  };

  function DraggableContainer(props) {
    return (
      <SortableContainer
        useDragHandle
        disableAutoscroll
        helperClass="row-dragging"
        onSortEnd={onSortEnd}
        {...props}
      />
    );
  }

  const rowSelection = {
    onChange: (key, rows) => {
      setSelectedRows(rows);
      setSelectedRowKeys(key);
    },
  };

  const onSearch = (e) => {
    const { value } = e.currentTarget;
    const searchArray = e.currentTarget.value ? menu : menuData;
    const data = utils.wildCardSearch(searchArray, value);
    if (data?.length === 0) {
      setMenu();
    }
    setMenu(data);
    setSelectedRowKeys([]);
  };

  const handleShowCategory = async (value) => {
    const data = await getItemsByCondition(value, 'menus', 'restaurantId');
    setIdInvited(value);
    setLoading(true);
    try {
      setMenu(data);
      setLoading(false);
    } catch (err) {
      return err;
    }
  };

  function DraggableBodyRow({ className, style, ...restProps }) {
    const index = menu.findIndex((item) => item.id === restProps['data-row-key']);

    return (
      <SortableItem
        index={index}
        {...restProps}
        selected={selectedItems.includes(index)}
        onClick={(e) => {
          e.stopPropagation();

          if (e.ctrlKey || e.metaKey) {
            setSelectedItems((prevSelectedItems) => {
              if (prevSelectedItems.includes(index)) {
                return prevSelectedItems.filter((item) => item !== index);
              }
              return [...prevSelectedItems, index];
            });
          } else {
            setSelectedItems([index]);
          }
        }}
      />
    );
  }

  return (
    <Card>
      <Flex
        alignItems={matches ? 'stretch' : 'center'}
        justifyContent="space-between"
        flexDirection={matches ? 'column' : 'row'}
        mobileFlex
      >
        <h3 style={{ color: colorText }}>Categories</h3>
        <Flex className="mb-1" mobileFlex={false}>
          <div className="mr-md-3 mb-3">
            <Input placeholder="Search" prefix={<SearchOutlined />} onChange={(e) => onSearch(e)} />
          </div>
        </Flex>
        <div style={{ display: 'flex' }}>
          <Button
            onClick={() => onBack()}
            type="primary"
            icon={<LeftOutlined />}
            block
            style={{ marginRight: '1rem' }}
          >
            Back Menu
          </Button>
          <Button
            onClick={() => addProduct(idInvited)}
            type="primary"
            icon={<PlusCircleOutlined />}
            block
          >
            Add Category
          </Button>
        </div>
      </Flex>
      <div className="table-responsive">
        {loading && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              position: 'absolute',
              left: 0,
              right: 0,
              top: '50%',
            }}
          >
            <CircularProgress
              style={{ color: '#5E4090', width: '5%', height: '5%' }}
              thickness={1}
            />
          </div>
        )}
        {!loading && (
          <List>
            <Table
              pagination={false}
              dataSource={menu}
              columns={tableColumns}
              rowKey="id"
              components={{
                body: {
                  wrapper: DraggableContainer,
                  row: DraggableBodyRow,
                },
              }}
              onRow={(record, index) => ({
                index,
              })}
            />
          </List>
        )}
      </div>
    </Card>
  );
}

export default MenuItems;
