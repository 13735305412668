/* eslint-disable no-else-return */
// eslint-disable-next-line import/prefer-default-export
import axios from 'axios';
import dayjs from 'dayjs';

import { serverTimestamp } from 'firebase/firestore';
import { createItemCustom } from './api';

const getImageBlob = async (imageUrl) => {
  const response = await fetch(imageUrl);
  // eslint-disable-next-line no-return-await
  return await response.blob();
};

const convertBlobToBase64 = (blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });

// eslint-disable-next-line import/prefer-default-export
export const whatsAppApi = async (
  restaurantName,
  quantityBottles,
  quantityCases,
  date,
  hour,
  nameProduct,
  imageUrl,
  phoneNumber,
  productId,
  restaurantId,
) => {
  const cleanNumber = phoneNumber.replace(/[^0-9]/g, '');

  const obj = {
    name_restaurant: restaurantName,
    number_bottles: quantityBottles,
    number_cases: quantityCases,
    date_order: dayjs(date).format('DD/MM/YYYY'),
    hour_time: hour,
    name_product: nameProduct,
    phoneNumber: `1${cleanNumber}`,
    image: '',
  };

  try {
    const imageBlob = await getImageBlob(imageUrl);
    const base64Image = await convertBlobToBase64(imageBlob);

    obj.image = base64Image;

    const response = await axios.post(
      'https://us-central1-fullaccezz-2756a.cloudfunctions.net/app/api/send-message',
      obj,
    );

    const objectCollection = {
      dateDelivery: dayjs(date).format('DD/MM/YYYY'),
      dateOrder: serverTimestamp(),
      idProduct: productId,
      quantityOrder: quantityBottles,
      casesOrder: quantityCases,
      restaurantId,
    };

    await createItemCustom(objectCollection, 'orderHistory');

    return response;
  } catch (error) {
    if (error.response) {
      return error.response.data.error.code;
    } else if (error.request) {
      return error.request.code;
    } else {
      return error.message;
    }
  }
};
