/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import React from 'react';
import {
  Input, Row, Col, Card, Form,
} from 'antd';

const rules = {
  name: [
    {
      required: true,
      message: 'Please enter product name',
    },
  ],
};

function EditCategory({ setName, name }) {
  return (
    <Row gutter={16}>
      <Col xs={24} sm={24} md={17}>
        <Card title="Basic Info">
          <Form.Item name="name" label="New Menu" rules={rules.name}>
            <Input placeholder="Name" onChange={(e) => setName(e?.target?.value)} defaultValue={name} />
          </Form.Item>
        </Card>
      </Col>
    </Row>
  );
}

export default EditCategory;
