/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import React from 'react';
import {
  Input, Row, Col, Card, Form, Checkbox,
} from 'antd';
import { LockOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';

const rules = {
  name: [
    {
      required: true,
      message: 'Please enter name',
    },
  ],
  email: [
    {
      required: true,
      message: 'Please enter email',
    },
  ],
  password: [
    {
      required: true,
      message: 'Please enter password',
    },
  ],
};

function InvitedStaff({
  setName, link, setLink, setToken,
}) {
  return (
    <Row gutter={16}>
      <Col xs={24} sm={24} md={17}>
        <Card>
          <Form.Item name="name" label="name" rules={rules.name}>
            <Input placeholder="Name" onChange={(e) => setName(e?.target?.value)} />
          </Form.Item>
          <Form.Item name="email" label="email" rules={rules.email}>
            <Input prefix={<MailOutlined style={{ color: '#007bff' }} />} placeholder="Email" onChange={(e) => setName(e?.target?.value)} />
          </Form.Item>
          <Form.Item name="password" label="password" rules={rules.password}>
            <Input.Password prefix={<LockOutlined style={{ color: '#007bff' }} />} placeholder="Password" onChange={(e) => setName(e?.target?.value)} />
          </Form.Item>
          <div
            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
          >
            <h5>Account Tier</h5>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox
                  color="primary"
                  onChange={(e) => {
                    setLink('');
                    setToken('');
                  }}
                  value="Manager"
                  sx={{ padding: 1, color: '#fff' }}
                />
                <p>Manager</p>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox
                  color="primary"
                  value="Staff"
                  sx={{ padding: 1, color: '#fff' }}
                />
                <p>Staff</p>
              </div>
            </div>
          </div>
          { link !== '' && (
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
            <p>{link}</p>
          </div>
          )}
        </Card>
      </Col>
    </Row>
  );
}

export default InvitedStaff;
