/* eslint-disable max-len */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo } from 'react';
import {
  Card, Table, Select, Input, Menu, message, Button,
} from 'antd';
import {
  SearchOutlined,
  EyeOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import utils from '../../utils';
import Flex from '../../components/shared-components/Flex';
import EllipsisDropdown from '../../components/shared-components/EllipsisDropdown';
import { getItemsByConditionGuestAdmin, updateItem } from '../../api/api';
import { useAuth } from '../../context/authContext';

const { Option } = Select;

const getPaymentStatus = (status) => {
  if (status === 'Paid') {
    return 'success';
  }
  if (status === 'Pending') {
    return 'warning';
  }
  if (status === 'Expired') {
    return 'error';
  }
  return '';
};

const getShippingStatus = (status) => {
  if (status === 'Ready') {
    return 'blue';
  }
  if (status === 'Shipped') {
    return 'cyan';
  }
  return '';
};

const paymentStatusList = ['Manager', 'Staff'];

function GuestManager() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [allData, setAllData] = useState([]);
  const [list, setList] = useState(allData);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [buttonDeleteLoad, setButtonDeleteLoad] = useState(false);
  const [id, setId] = useState();
  const [band, setBand] = useState(false);

  const [themeInputs, setThemeInputs] = useState();
  const [themeCell, setThemeCell] = useState();
  const { headerNavColor, currentTheme } = useSelector((state) => state.theme);
  const isDarkTheme = currentTheme === 'dark';

  const colorText = useMemo(() => {
    if (isDarkTheme) {
      setThemeInputs('#1b2531');
      setThemeCell('rgb(0 0 0)');
      return '#fff';
    }
    setThemeCell('rgb(148 152 163)');
    setThemeInputs('#fafafb');
    return '#455560';
  }, [isDarkTheme]);

  const dataUserRestaurant = async () => {
    try {
      const data = await getItemsByConditionGuestAdmin(user?.uid, 'guests', 'restaurantId');
      setAllData(data);
      setList(data);
    } catch (err) {
      return err;
    }
  };
  useEffect(() => {
    dataUserRestaurant();
  }, []);

  const handleShowStatus = (value) => {
    if (value !== 'All') {
      const key = 'rol';
      const data = utils.filterArray(allData, key, value);
      setList(data);
    } else {
      setList(allData);
    }
  };

  const deleteRow = async (row) => {
    try {
      setButtonDeleteLoad(true);
      setBand(true);
      let newArr = [];
      let idOwner;
      allData.filter((item, i) => {
        if (row?.email !== item?.email) {
          newArr.push(item);
          idOwner = item?.restaurantId;
        }
        return newArr;
      });
      const newObj = {
        owner: idOwner,
        listRestaurants: newArr,
      };

      await updateItem(id, newObj, 'guestPostbyAdmin');

      setButtonDeleteLoad(false);
      setBand(false);
      message.success('Account Removed');
      setTimeout(() => {
        newArr = [];
      }, 50);

      const objKey = 'email';
      let data = list;
      if (selectedRows.length > 1) {
        selectedRows.forEach((elm) => {
          data = utils.deleteArrayRow(data, objKey, elm.email);
          setList(data);
          setSelectedRows([]);
        });
      } else {
        data = utils.deleteArrayRow(data, objKey, row.email);
        setList(data);
      }
    } catch (err) {
      message.error(err.message);
    }
  };

  const dropdownMenu = (row) => (
    <Menu>
      <Menu.Item onClick={() => navigate(`/${row?.id}/guest`)}>
        <Flex alignItems="center">
          <EyeOutlined />
          <span className="ml-2">{selectedRows.length > 0 ? `View (${selectedRows.length})` : 'View'}</span>
        </Flex>
      </Menu.Item>
    </Menu>
  );

  const tableColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a, b) => utils.antdTableSorter(a, b, 'name'),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      sorter: (a, b) => utils.antdTableSorter(a, b, 'email'),
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      sorter: (a, b) => utils.antdTableSorter(a, b, 'phone'),
    },
    {
      title: 'Last Visit',
      dataIndex: 'lastVisit',
      render: (_, record) => (
        <div>
          {new Date(record?.lastVisit?.seconds * 1000)
            .toString()
            .split(' ', 4)
            .join(' ')}
        </div>
      ),
      /* sorter: (a, b) => utils.antdTableSorter(a, b, 'lastVisit'), */
    },
    {
      title: 'Total Visit',
      dataIndex: 'numberOfVisits',
      sorter: (a, b) => utils.antdTableSorter(a, b, 'numberOfVisits'),
    },
    {
      title: 'Total Spend',
      dataIndex: 'totalSpend',
      render: (_, record) => (
        <div>
          -
        </div>
      ),
      sorter: (a, b) => utils.antdTableSorter(a, b, 'totalSpend'),
    },
    {
      title: '',
      dataIndex: 'actions',
      render: (_, elm) => (
        <div className="text-right">
          <EllipsisDropdown menu={dropdownMenu(elm)} />
        </div>
      ),
    },
  ];

  const rowSelection = {
    onChange: (key, rows) => {
      setSelectedRows(rows);
      setSelectedRowKeys(key);
    },
  };

  const onSearch = (e) => {
    const { value } = e.currentTarget;
    const searchArray = e.currentTarget.value ? list : allData;
    const data = utils.wildCardSearch(searchArray, value);
    setList(data);
    setSelectedRowKeys([]);
  };

  const addNewInvited = (idInvited) => {
    navigate('invited-staff');
  };

  return (
    <Card>
      <Flex alignItems="center" justifyContent="space-between" mobileFlex={false}>
        <h3 style={{ color: colorText }}>Restaurant Guests</h3>
        <Flex className="mb-1" mobileFlex={false}>
          <div className="mr-md-3 mb-3">
            <Input placeholder="Search" prefix={<SearchOutlined />} onChange={(e) => onSearch(e)} />
          </div>
          {/* <div className="mb-3">
            <Select
              defaultValue="All"
              className="w-100"
              style={{ minWidth: 180 }}
              onChange={handleShowStatus}
              placeholder="Status"
            >
              <Option value="All">All personnel </Option>
              {paymentStatusList.map((elm) => (
                <Option key={elm} value={elm}>
                  {elm}
                </Option>
              ))}
            </Select>
          </div> */}
        </Flex>
        {/* <div>
          <Button type="primary" icon={<PlusCircleOutlined />} block onClick={() => addNewInvited()}>New Staff</Button>
        </div> */}
      </Flex>
      <div className="table-responsive">
        <Table
          columns={tableColumns}
          dataSource={list}
          rowKey="id"
        />
      </div>
    </Card>
  );
}

export default GuestManager;
