// Init
import React from 'react';
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import 'react-toastify/dist/ReactToastify.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-circular-progressbar/dist/styles.css';
import 'react-phone-number-input/style.css';
import Layouts from './layouts';
import { THEME_CONFIG } from './configs/AppConfig';

// Files
import './css/index.css';
// Component

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

function App() {
  return (
    <div className="app">
      <ThemeSwitcherProvider
        themeMap={themes}
        defaultTheme={THEME_CONFIG.currentTheme}
        insertionPoint="styles-insertion-point"
      >
        <Layouts />
      </ThemeSwitcherProvider>
    </div>
  );
}

// Export
export default App;
