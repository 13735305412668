/* eslint-disable consistent-return */
/* eslint-disable import/prefer-default-export */
import { useEffect, useState, useCallback } from 'react';
import dayjs from 'dayjs';
import { getItemsByConditionGuestAdminSnapshot } from '../../../api/api';

export const useOrderHistory = ({ productData }) => {
  const [list, setList] = useState([]);

  const dataProduct = useCallback(async () => {
    try {
      const callbackSnap = (values) => {
        const newValues = values.map((item) => ({
          quantityBottles: item.quantityOrder || 0,
          quantityCases: item.casesOrder || 0,
          requestDate: item.dateOrder,
          deliveryDate: dayjs(item.dateDelivery, 'DD/MM/YYYY').toDate(),
          status: 'Success',
        }));

        const formatValues = newValues.sort(
          (a, b) => new Date(b.deliveryDate) - new Date(a.deliveryDate),
        );

        setList(formatValues);
      };

      if (productData && productData.productId) {
        await getItemsByConditionGuestAdminSnapshot(
          productData.productId,
          'orderHistory',
          'idProduct',
          callbackSnap,
        );
      }
    } catch (err) {
      return err;
    }
  }, [productData]);

  useEffect(() => {
    dataProduct();
  }, [dataProduct]);

  return {
    list,
  };
};
