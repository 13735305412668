/* eslint-disable operator-linebreak */
import React, { useCallback, useEffect, useState } from 'react';

import { Card, Col, Row } from 'antd';
import { FormDelivery } from './FormDelivery';
import { whatsAppApi } from '../../api/whatsappApi';
import OrderHistory from './OrderHistory';
import { ERROR_402_WHAPI, ERROR_500_WHAPI, SUCCES_200_WHAPI } from '../../constants/whapiConstant';

export default function Delivery({ productData }) {
  const [statusCode, setStatusCode] = useState(false);
  const [textStatus, setTextStatus] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [dataWhats, setDataWhats] = useState({
    restaurantName: '',
    nameProduct: '',
    imageUrl: '',
    phoneNumber: '',
    productId: '',
    restaurantId: '',
  });

  const initData = useCallback(async () => {
    if (productData) {
      setDataWhats({
        restaurantName: productData.restaurantName,
        nameProduct: productData.name,
        phoneNumber: productData.telephoneNumber,
        imageUrl: productData.image,
        productId: productData.productId,
        restaurantId: productData.restaurantId,
      });
    }
  }, [productData]);

  const handleSubmit = async (data) => {
    const { restaurantName, nameProduct, imageUrl, phoneNumber, productId, restaurantId } =
      dataWhats;
    const { quantityBottles, quantityCases, date, hour } = data;

    const response = await whatsAppApi(
      restaurantName,
      quantityBottles,
      quantityCases,
      date,
      hour,
      nameProduct,
      imageUrl,
      phoneNumber,
      productId,
      restaurantId,
    );

    switch (response) {
      case 402:
        setStatusCode(0);
        setTextStatus(ERROR_402_WHAPI);
        setIsLoading(false);
        break;
      case 500:
        setStatusCode(0);
        setTextStatus(ERROR_500_WHAPI);
        setIsLoading(false);
        break;
      case 200:
        setStatusCode(1);
        setTextStatus(SUCCES_200_WHAPI);
        setIsLoading(false);
        break;
      default:
        setStatusCode(1);
        setTextStatus(SUCCES_200_WHAPI);
        setIsLoading(false);
    }
  };

  useEffect(() => {
    initData();
  }, [initData]);

  return (
    <Row gutter={24} className="w-100">
      <Col xs={24} sm={24} md={26}>
        <Card title="Delivery">
          <FormDelivery
            handleSubmit={handleSubmit}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            textStatus={textStatus}
            statusCode={statusCode}
          />
        </Card>
      </Col>
      <Col xs={24} sm={24} md={26}>
        <Card>
          <OrderHistory productData={productData} />
        </Card>
      </Col>
    </Row>
  );
}
